import { useEffect, useState } from "react";
import TransferBlock from "../../Components/dash/transfer/transferBlock";
import TransferHistoryReceived from "../../Components/dash/transfer/transferHistoryReceived";
import TransferHistorySent from "../../Components/dash/transfer/transferHistorySent";
import { useUserAuth } from "../../Contexts/authContext";
import { CommonCard } from "../../Utility/Utility";

const Transfer = () => {

  const auth = useUserAuth();

  const [historyKey, setHistoryKey] = useState(1);

  useEffect(() => {
    setHistoryKey(historyKey+1);
  }, [auth]);

  return (
    <div className="xl:grid grid-cols-[30%_70%] xl:space-x-5 space-y-5 xl:space-y-0 my-6">
      <div><TransferBlock /></div>
      <CommonCard>
        <><TransferHistorySent key={historyKey} /></>
        <><TransferHistoryReceived key={historyKey} /></>
      </CommonCard>
    </div>
  );
};
export default Transfer;