import React from 'react';
import useDynamicTable, { DynamicPagination } from '../../Hooks/dynamicTable';
import { CommonCard, ContentLoading, EmptyRecords, TitlePrimary } from '../../Utility/Utility';
import { useHelpers } from '../../Hooks/helpers';
import Timestamp from 'react-timestamp';
import { CircleSlash, View } from 'lucide-react';
import { IconButton, Tooltip } from '@material-tailwind/react';
import appConfig from '../../Utility/AppConfig';
import { useNavigate } from 'react-router-dom';

const Licenses = () => {

  const helper = useHelpers();
  const navigate = useNavigate();

  const { data, loading, currentPage, totalPages, handlePageChange } = useDynamicTable('user/getUserLicenses');

  let tableHead = [
    <>License #</>, 
    <>Upline License #</>, 
    <>Earnings Start</>,
    <>Total Claimed</>,
    // <>Daily Earnings</>,
    <>Purchased On</>,
    <></>
  ];

  return (
    <div className='mt-6'>
      <CommonCard>
        <TitlePrimary>My Licenses</TitlePrimary>
        <div className="w-full overflow-scroll px-0 table-custom">
          <table className="w-full">
            <thead>
              <tr>
                {tableHead.map((head, key) => (
                  <th key={key} className="">{head}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {(data.length > 0 && !loading) && data.map(
                (d, index) => {
                  const classes =  "p-4";
                  return (
                    <tr key={index}>
                      <td className={classes}>
                        {d.license_id}
                      </td>
                      <td className={classes}>
                        {d.upline_id}
                      </td>
                      {d.startTime*1 > 0 ?
                        <td className={classes}>
                          <Timestamp date={d.startTime} /><br/>
                          <Timestamp date={d.startTime} relative className='opacity-60' />
                        </td>
                      :
                        <td className='flex justify-center'>
                          <CircleSlash size={15} className='mt-[10px]' />
                        </td>
                      }
                      <td className={classes}>
                        <helper.AmountToCurrency amount={d.totalClaimed} /> / 108 USDT
                      </td>
                      {/* {d.isExpired ?
                        <td className={classes}>
                          <helper.renderStatusChip status='Expired' color='red' />
                        </td>
                      : d.startTime*1 > 0 ?
                        <td className={classes}>
                          <helper.renderStatusChip status='Active' />
                        </td>
                      :
                        <td className={classes}>
                          <helper.renderStatusChip status='Not Started' color='black' />
                        </td>
                      } */}
                      <td className={classes}>
                        <Timestamp date={d.created} /><br/>
                        <Timestamp date={d.created} relative className='opacity-60' />
                      </td>
                      <td className='flex gap-1 justify-end'>
                        <Tooltip content='View'><IconButton onClick={() => navigate('/license/' + d.license_id)} size='sm' color='amber' variant='gradient'><View size={15} /></IconButton></Tooltip>
                      </td>
                    </tr>
                  );
                },
              )}
              {loading && 
                <tr>
                  <td colSpan={tableHead.length}><div className='flex justify-center pt-3'><ContentLoading /></div></td>
                </tr>
              }
              {((!data || data.length === 0) && !loading) && 
                <tr>
                  <td colSpan={tableHead.length}><div className='flex justify-center pt-3 opacity-50'>No rows found</div></td>
                </tr>
              }
            </tbody>
          </table>
        </div>
        <DynamicPagination totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
      </CommonCard>
    </div>
  );
};

export default Licenses;