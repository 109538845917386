import { Button } from "@material-tailwind/react";
import { Anvil, ArrowBigDownDash, HandCoins } from "lucide-react";
import React, { useState } from "react";
import { useAppDialog } from "../../../Hooks/appDialog";
import { useCallAPI } from "../../../Hooks/callAPI";
import { useHelpers } from "../../../Hooks/helpers";
import { useWaitTransaction } from "../../../Hooks/waitTransaction";
import { TitlePrimary } from "../../../Utility/Utility";
import { AppDialog } from "../../modals/AppDialog";
import { ProcessingTxnDialog } from "../../modals/ProcessingTxnDialog";

const ClaimRewards = ({ data, refreshData }) => {

  const callAPI = useCallAPI();
  const helper = useHelpers();
  const waitTransaction = useWaitTransaction();
  const pendingTxDialog = useAppDialog();
  const appDialog = useAppDialog();

  const [loading, setLoading] = useState(false);

  const processAction = async () => {
    try {
      setLoading(true);
      const hash = await callAPI('user/claimAllLicensesRewards');

      //Wait for transaction
      await waitTransaction.wait(hash.hash, 'Rewards Claimed Successfully', pendingTxDialog, appDialog);
      await refreshData('yes');
      setLoading(false);
      
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <div className="bg-[#222222]  flex flex-col h-full">
      <div className="p-6">
        <TitlePrimary>Daily Win Rewards</TitlePrimary>
        <div className="space-y-6">
          <div className="flex justify-start gap-3 items-center">
            <Anvil size={40} className="text-grey" />    
            <div>
              <h6 className="bg-gradient-to-r from-yellowLight to-yellowDark bg-clip-text leading-6 text-transparent text-[22px] font-bold">
                {data?.totalLicenses}
              </h6>
              <p className="text-sm text-grey font-normal">Licenses you own</p>
            </div>
          </div>
          <div className="flex justify-start gap-3 items-center">
            <HandCoins size={40} className="text-grey" />          
            <div>
              <h6 className="bg-gradient-to-r from-yellowLight to-yellowDark bg-clip-text leading-6 text-transparent text-[22px] font-bold">
                <helper.AmountToCurrency amount={data?.licenseUnclaimedRewards} decimals={5} />
              </h6>
              <p className="text-sm text-grey font-normal">Unclaimed Rewards</p>
            </div>
          </div>
        </div>
        <ProcessingTxnDialog hook={pendingTxDialog} />
        <AppDialog hook={appDialog} />
      </div>
      <Button onClick={processAction} disabled={loading} loading={loading} className="rounded-none mt-auto w-full bg-gradient-to-r from-yellowDark to-yellowLight text-white font-semibold text-lg text-left flex justify-between items-center h-14 capitalize">
        Claim Rewards
        <ArrowBigDownDash />
      </Button>
    </div>
  );
};
export default ClaimRewards;
