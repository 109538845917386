import { Button } from "@material-tailwind/react";
import { ArrowBigDownDash } from "lucide-react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import IconLogin from "../../../Assets/plus.png";
import { useUserAuth } from "../../../Contexts/authContext";
import { useAppDialog } from "../../../Hooks/appDialog";
import { useCallAPI } from "../../../Hooks/callAPI";
import { useHelpers } from "../../../Hooks/helpers";
import { useWaitWithdrawal } from "../../../Hooks/waitWithdrawal";
import appConfig from "../../../Utility/AppConfig";
import { CommonCard, ContentLoading, Title } from "../../../Utility/Utility";
import { AppDialog } from "../../modals/AppDialog";
import { ProcessingDex3Dialog } from "../../modals/ProcessingDex3Dialog";
import { ProcessingTxnDialog } from "../../modals/ProcessingTxnDialog";
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const WithdrawBlock = () => {

  const callAPI = useCallAPI();
  const auth = useUserAuth();
  const helper = useHelpers();
  const waitWithdrawal = useWaitWithdrawal();
  const appDialog = useAppDialog();
  const pendingTxDialog = useAppDialog();
  const waitingClaimDialog = useAppDialog();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState({});

  useEffect(() => {
    if(auth.loggedIn) {
      checkWithdrawCallback();
    }
  }, [auth.loggedIn]);

  const checkWithdrawCallback = async () => {
    try {
      // Check here if any params is present
      if(params.orderid) {
        if(params.status === 'success') {
              
          //Get payout link
          const payoutid = await callAPI('user/getpayoutbyid/' + params.orderid);
          await waitWithdrawal.waitClaim(params.orderid, () => window.open(appConfig.payoutLink +  payoutid, '_self'), waitingClaimDialog, appDialog);
          await auth.bootApp();
          
        } else if(params.status === 'cancel') {
          toast.error('Donation Claim Cancelled by user.');
        }
      }

    } catch (error) {
      console.log(error);
    }
  }

  const withdraw = async () => {
    try {

      if(auth?.appData?.user.balance*1 <= 0) return toast.error('Nothing to claim');

      setLoading(true);
      const res = await callAPI('user/createWithdrawal', { amount: getValues().amount });

      //Wait for transaction
      await waitWithdrawal.waitProcess(res.orderId, pendingTxDialog);
      
      //Get payment link
      const payoutid = await callAPI('user/getpayoutbyid/' + res.orderId);
      window.open(appConfig.payoutLink +  payoutid, '_self');

    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const FormSchema = yup.object().shape({
    amount: yup.number().label('Amount must be a number')
      .required('Amount is required')
      .min(0.01, 'Amount must be at least 0.01')
      .test('is-decimal', 'Amount must be a decimal up to 2 places', (value) => {
        if (value) {
          const decimalPlaces = (value.toString().split('.')[1] || []).length;
          return decimalPlaces <= 2;
        }
        return true;
      })
  });

  const {
      getValues,
      register,
      setValue,
      formState: { errors, isValid }
  } = useForm({ mode: 'onChange', resolver: yupResolver(FormSchema) })

  return (
    <>
      <CommonCard>
        <div className="mb-2">
          <Title
            title={{ icon: <ArrowBigDownDash className='w-6 text-yellowDark' />, label: "Withdraw USDT" }}
          />
        </div>
        <div className="text-left">
          {auth?.appData?.user?.balance ?
            <div className="text-left">
              <div className="mt-5">
                <h4 className="text-2xl font-bold text-yellowLight"><helper.AmountToCurrency amount={auth?.appData.user.balance} decimals={2} /></h4>
                <p className="text-grey">Available USDT</p>
              </div>
              <div className="font-semibold text-md mt-7 leading-5 text-gray-700">
                Enter amount to withdraw in USD
              </div>
              <div className="my-4">
                <input
                    type="text"
                    placeholder="0"
                    {...register('amount')}
                    className="border-2 rounded-lg border-[#888] text-white w-full h-[45px] bg-transparent px-4 py-5 focus:outline-none placeholder:text-lightblue"
                />
                {formError?.address && <p className="text-red-400 text-xs my-2">{formError?.address.msg}</p>}
                {errors.amount?.message && <p className="text-red-400 text-xs my-2">{errors.amount?.message}</p>}
                {formError?.amount && <p className="text-red-400 text-xs my-2">{formError?.amount.msg}</p>}
              </div>
              <div className="flex justify-start mb-3 mt-5">
                <Button
                  disabled={auth?.appData.user.balance*1 <= 0 || loading}
                  loading={loading}
                  onClick={withdraw}
                  variant="gradient"
                  className="bg-gradient-to-r from-purpleDark to-purpleLight font-bold text-lg rounded-lg capitalize"
                  >
                  Withdraw Now
                </Button>
              </div>
              <div className="text-grey text-sm">
                USDT will be withdrawn to <helper.trimAddress address={auth?.appData.user.auth_address} copy />
              </div>
            </div>
          :
            <ContentLoading />
          }
        </div>
      </CommonCard>
      <ProcessingTxnDialog hook={pendingTxDialog} />
      <ProcessingDex3Dialog hook={waitingClaimDialog} />
      <AppDialog hook={appDialog} />
    </>
  );
};
export default WithdrawBlock;