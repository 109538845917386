import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from "@material-tailwind/react";
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi';
import * as yup from 'yup';
import iconTicket from "../../Assets/iconTicket.png";
import { AppDialog } from '../../Components/modals/AppDialog';
import { ProcessingTxnDialog } from '../../Components/modals/ProcessingTxnDialog';
import { useAppDialog } from '../../Hooks/appDialog';
import { useCallAPI } from '../../Hooks/callAPI';
import { useWaitRegister } from '../../Hooks/waitRegister';
import { useWrite } from '../../Hooks/writeContract';
import { TitlePrimary } from "../../Utility/Utility";
import HowToStart from './HowToStart';

const Register = () => {

    const callAPI = useCallAPI();
    const account = useAccount();
    const writeContract = useWrite();
    const waitTxn = useWaitRegister();
    const pendingTxDialog = useAppDialog();
    const appDialog = useAppDialog();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState({});
    const [connectAddr, setConnectAddr] = useState(account.address);

    useEffect(() => {
      setConnectAddr(account.address);
    }, [account.address]);

    const FormSchema = yup.object().shape({
      referrerUsername: yup.string().max(19).label("Referrer Username").required("Referrer username is required").matches(/^[a-zA-Z0-9]+$/, 'Alphanumeric only')
    });

    const {
        getValues,
        register,
        setValue,
        formState: { errors, isValid }
    } = useForm({ mode: 'onChange', resolver: yupResolver(FormSchema) })

    useEffect(() => {
        if(localStorage.getItem("referrerUsername") !== null && localStorage.getItem("referrerUsername") !== undefined) {
            setValue("referrerUsername", localStorage.getItem("referrerUsername"));
        }
    }, []);
    
    const registerNow = async () => {
      try {

        setLoading(true);
        setFormError({});

        const formData = getValues();
        formData.address = connectAddr;
        const res = await callAPI("auth/getRegisterNonce", formData);
        const signature = await writeContract.SignMessage(res.nonce, connectAddr);
        
        const hash = await callAPI("auth/completeRegistration", { hash: signature, address: connectAddr });
        await waitTxn.wait(hash, pendingTxDialog, appDialog);

        navigate('/login/yes');
        setLoading(false);

      } catch (error) {
        if(error.code && error.code === 403) { //Handle form error
            setFormError(error.errData);
        }
        console.log(error);
        setLoading(false);
      }
    }

    return (
      <>
        <TitlePrimary>Register Now</TitlePrimary>
        <div className="">
          <div className='mb-8'>
            <p className="text-white/70 text-base mb-4">
              {!account.isConnected ?
                <>Connect your wallet 👇</>
              :
                <>Wallet Connected 👍</>
              }
            </p>
            <w3m-button />
          </div>

          <div className='mb-8'>
            <p className="text-white/70 text-base mb-4">
              Enter your referral username
            </p>
            <div className="relative mb-2">
              <img
                className="absolute left-0 top-1/2 -translate-y-1/2 ml-4"
                src={iconTicket}
                alt=""
              />
              <input
                  type="text"
                  placeholder="Referrer Username"
                  {...register('referrerUsername')}
                  className="border-[#E5E5E5] rounded-lg border w-full h-[45px] bg-transparent px-4 pl-12 focus:outline-none placeholder:text-yellowDark text-yellowLight"
              />
            </div>
            {formError?.address && <p className="text-red-400 text-xs my-2">{formError?.address.msg}</p>}
            {errors.referrerUsername?.message && <p className="text-red-400 text-xs my-2">{errors.referrerUsername?.message}</p>}
            {formError?.referrerUsername && <p className="text-red-400 text-xs my-2">{formError?.referrerUsername.msg}</p>}
          </div>

          <div className=''>
            <p className="text-white/70 text-base mb-4">
              Sign a nonce request from your wallet to register. <br/>You will not spend any BNB to login.
            </p>
            <Button 
            loading={loading}
            disabled={account.isConnected !== true || loading}
            onClick={registerNow}
            className="bg-gradient-to-r from-yellowLight to-yellowDark text-darker text-xl capitalize font-bold rounded-lg px-10 sm:px-20"
            >
              Register Now
            </Button>
            <p onClick={() => navigate('/login')} className="text-grey text-sm cursor-pointer mt-4">
              Already regsitered? Login Now
            </p>
          </div>
        </div>
        <HowToStart />
        <ProcessingTxnDialog hook={pendingTxDialog} />
        <AppDialog hook={appDialog} />
      </>
    );
};

export default Register;
