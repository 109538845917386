import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { PlusSquare } from 'react-feather';
import { useForm } from 'react-hook-form';
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import * as yup from 'yup';
import gas from '../../../Assets/charging-station.png';
import { useUserAuth } from "../../../Contexts/authContext";
import { useAppDialog } from "../../../Hooks/appDialog";
import { useCallAPI } from "../../../Hooks/callAPI";
import { useHelpers } from "../../../Hooks/helpers";
import { useWaitPurchase } from "../../../Hooks/waitPurchase";
import appConfig from "../../../Utility/AppConfig";
import { FormToggle } from '../../../Utility/Inputs';
import { CommonCard, ContentLoading, Title } from "../../../Utility/Utility";
import { AppDialog } from "../../modals/AppDialog";
import { ProcessingDex3Dialog } from "../../modals/ProcessingDex3Dialog";
import { BadgeDollarSign, Fuel } from 'lucide-react';

const PurchaseBlock = () => {

  const callAPI = useCallAPI();
  const auth = useUserAuth();
  const helper = useHelpers();
  const waitTx = useWaitPurchase();
  const appDialog = useAppDialog();
  const pendingTxDialog = useAppDialog();
  const params = useParams();
  
  const [addFundsData, setAddFundsData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState({});
 
  useEffect(() => {
    if(auth.loggedIn) {
      bootPurchase();
    }
  }, [auth.loggedIn]);

  const bootPurchase = async () => {
    try {

      //Get user balance and check gas
      const res = await callAPI('user/getAddFundsData');
      setAddFundsData(res);

      if(res.userBalance*1 < 0.001) {
        setValue('topupGas', true);
      }

      // Check here if any params is present
      if(params.orderid) {
        if(params.status === 'success') {
          const paymentData = await callAPI('user/checkpurchase/' + params.orderid);
          await waitTx.wait(params.orderid, () => window.open(appConfig.payLink + paymentData.payment_id, '_self'), pendingTxDialog, appDialog);
          await auth.bootApp();
        } else if(params.status === 'cancel') {
          toast.error('Add Funds Cancelled by user.');
        }
      }

    } catch (error) {
      console.log(error);
    }
  }
  
  const purchase = async () => {
    try {
      setLoading(true);
      const res = await callAPI('user/addFunds', { amount: getValues('amount'), topupGas: getValues('topupGas') });
      window.open(res.payLink, '_self');
    } catch (error) {
      if(error.code && error.code === 403) { //Handle form error
        setFormError(error.errData);
      }
      setLoading(false);
      console.log(error);
    }
  }

  const FormSchema = yup.object().shape({
    amount: yup.number().label('Amount must be a number')
      .required('Amount is required')
      .min(1, 'Amount must be at least 1')
      .max(10000, 'Amount cannot exceed 1000')
      .test('is-decimal', 'Amount must be a decimal up to 2 places', (value) => {
        if (value) {
          const decimalPlaces = (value.toString().split('.')[1] || []).length;
          return decimalPlaces <= 2;
        }
        return true;
      }),
    topupGas: yup.boolean().optional().nullable(),
  });

  const {
      getValues,
      register,
      setValue,
      formState: { errors, isValid }
  } = useForm({ defaultValues: { amount: 50, topupGas: false }, mode: 'onChange', resolver: yupResolver(FormSchema) })

  const updateToggle = (e) => {
    setValue('topupGas', e.target.checked);
  }

  return (
    <>
      <CommonCard>
        <div className="mb-2">
          <Title
            title={{ icon: <BadgeDollarSign size={25} className='text-yellowDark' />, label: "Deposit USDT" }}
          />
        </div>
        <div className="text-left">
          <div className="mt-5 border-b border-dashed border-gray-800 pb-4">
            <h4 className="text-2xl font-bold text-yellowLight"><helper.AmountToCurrency amount={auth?.appData?.user?.balance} decimals={4} /></h4>
            <p className="text-grey">My Available Balance</p>
          </div>
          <div className="font-semibold text-md mt-5 text-grey">
            Enter amount in USD to deposit
          </div>
          <div className="my-4">
            <input
                type="text"
                placeholder="0"
                {...register('amount')}
                className="border-2 rounded-lg border-grey  w-full h-[45px] bg-transparent px-4 py-5 focus:outline-none placeholder:text-yellowDark text-yellowDark"
            />
            {formError?.address && <p className="text-red-400 text-xs my-2">{formError?.address.msg}</p>}
            {errors.amount?.message && <p className="text-red-400 text-xs my-2">{errors.amount?.message}</p>}
            {formError?.amount && <p className="text-red-400 text-xs my-2">{formError?.amount.msg}</p>}
          </div>
          {addFundsData ?
            <>
              <div className='flex justify-between items-center border-dashed border-2 border-gray-800 p-2 rounded-md mb-4'>
                <Fuel className='text-yellowDark' />
                <div className='text-right'>
                  <p className='text-xs text-grey'>Available Gas</p>
                  <p className='text-yellowDark font-bold text-md'>{addFundsData.userBalance} BNB</p>
                </div>
              </div>
              <div className=''>
                <FormToggle
                    title='Topup Gas?' 
                    helper={`Gas is required to buy licenses, activate residual, upgrade stars etc. Gas is paid to the blockchain and is not collected by ${appConfig.appName}. Receive $${addFundsData.gasTopUpUsd/100} worth BNB to your wallet by turining the Switch On. If Gas is lower than threshold, the switch is turned on by default.`}
                    name='topupGas'
                    disabled={addFundsData.userBalance*1 < 0.001}
                    checked={addFundsData.userBalance*1 < 0.001}
                    onChange={updateToggle}
                />
              </div>
            </>
          : 
            <ContentLoading />
          }
          <div className="flex justify-left mt-5">
            <Button
              loading={loading}
              disabled={loading || !isValid}
              onClick={purchase}
              variant="gradient"
              className="bg-gradient-to-r from-purpleDark to-purpleLight font-bold text-lg rounded-lg capitalize"
            >Proceed</Button>
          </div>
        </div>
      </CommonCard>
      <ProcessingDex3Dialog hook={pendingTxDialog} />
      <AppDialog hook={appDialog} />
    </>
  );
};
export default PurchaseBlock;