import { useEffect, useState } from 'react';
import { useCallAPI } from './callAPI';
import { useUserAuth } from '../Contexts/authContext';
import { IconButton } from '@material-tailwind/react';

const useDynamicTable = (api, fromDate = null, toDate = null, searchFilters = false) => {

	const callAPI = useCallAPI();
	const auth = useUserAuth();

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);

	const fetchData = async () => {
		setLoading(true);
		try {
			const res = await callAPI(api, {
				params: {
					page: currentPage,
					pageSize: 10, // Adjust page size as needed
					fromDate: fromDate,
					toDate: toDate,
					...searchFilters,
				}
			});
			setData(res.result.rows);
			setTotalPages(res.result.totalPages);
			setLoading(false);
		} catch (error) {
			console.error('Error fetching data:', error);
			setLoading(false);
		}
	};

	useEffect(() => {

		if(auth.loggedIn)
			fetchData();

		// Cleanup function
		return () => {
		// Cleanup logic if needed
		};

	}, [auth.loggedIn, currentPage, searchFilters]);

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	return { data, loading, currentPage, totalPages, handlePageChange, fetchData };
};

export const DynamicPagination = ({ totalPages, currentPage, handlePageChange }) => {
	return (
		<div className="p-4">
			<div className="flex flex-wrap justify-center items-center gap-2">
				{Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
					<IconButton key={page} onClick={() => handlePageChange(page)} variant={currentPage === page ? 'gradient':'outlined'} color='amber' size="sm">{page}</IconButton>
				))}
			</div>
		</div>
	);
}

export default useDynamicTable;
