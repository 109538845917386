import { CheckBadgeIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import toast from "react-hot-toast";
import { useCallAPI } from "./callAPI";
import { usePollAPI } from "./pollAPI";
import { useNavigate } from "react-router-dom";

export const useWaitPurchase = () => {

    const callAPI = useCallAPI();
    const pollResults = usePollAPI();
    const navigate = useNavigate();
        
    const wait = (order_id, openLink, pendingDialog, appDialog) => {
        
        pendingDialog.open({
            header: 'Processing Payment..',
            description: 'Please wait while the payment is being processed. This should take 0-2 minutes.',
            backdropDismiss: true,
            linkType: 'payment',
            openLink: openLink,
            order_id: order_id
        });
        
        return new Promise(async (resolve, reject) => {
            try {
                const apiToPoll = () => callAPI("user/checkpurchase/" + order_id);
                const pollContinueCondition = (res) => res.status === "pending" || res.status === "received" || res.status === "processing";
                const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
                pendingDialog.close();

                if(pollRes.status == "completed") {
                    appDialog.open({
                        icon: <CheckCircleIcon className="w-[60px] text-green-400" />,
                        header: `Funds added Successfully!`,
                        description: 'Your added USD is credited to your balance. ',
                        backdropDismiss: true,
                        buttons: [
                            {
                                text: 'Go to Dashboard',
                                color: 'purple',
                                action: () => navigate('/dashboard')
                            }
                        ]
                    });
                    return resolve(true);
                } else if(pollRes.status == "failed") {
                    toast.error("Add funds failed");
                    return reject(false);
                }
            } catch (error) {
                console.log(error.message);
                reject(false);
            }
        });
    }

    return { wait };

};