import { Button } from "@material-tailwind/react";
import { Anvil, DiamondPlus, HandCoins, Star } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../../Contexts/authContext";
import { useAppDialog } from "../../../Hooks/appDialog";
import { useCallAPI } from "../../../Hooks/callAPI";
import { useWaitTransaction } from "../../../Hooks/waitTransaction";
import { TitlePrimary } from "../../../Utility/Utility";
import { AppDialog } from "../../modals/AppDialog";
import { ProcessingTxnDialog } from "../../modals/ProcessingTxnDialog";

const UpgradeStar = ({ data, refreshData }) => {

  const auth = useUserAuth();
  const callAPI = useCallAPI();
  const waitTransaction = useWaitTransaction();
  const pendingTxDialog = useAppDialog();
  const appDialog = useAppDialog();

  const [loading, setLoading] = useState(false);

  const processAction = async () => {
    try {
      setLoading(true);
      const hash = await callAPI('user/upgradeStar');

      //Wait for transaction
      await waitTransaction.wait(hash.hash, 'Star Upgraded Successfully', pendingTxDialog, appDialog);
      await refreshData('yes');
      await auth.bootApp();
      setLoading(false);
      
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <>
      <div className="bg-[#222222]  flex flex-col h-full">
        <div className="p-6">
          <TitlePrimary>Upgrade Star</TitlePrimary>
          <div className="space-y-6">
            <div className="flex justify-start gap-3 items-center">
              <Star size={40} className="text-grey" />    
              <div>
                <h6 className="bg-gradient-to-r from-yellowLight to-yellowDark bg-clip-text leading-6 text-transparent text-[22px] font-bold">
                  {data?.currentStar}
                </h6>
                <p className="text-sm text-grey font-normal">Current Star</p>
              </div>
            </div>
            {data?.availableStar > data?.currentStar &&
              <div className="flex justify-start gap-3 items-center">
                <HandCoins size={40} className="text-grey" />          
                <div>
                  <h6 className="bg-gradient-to-r from-yellowLight to-yellowDark bg-clip-text leading-6 text-transparent text-[22px] font-bold">
                    {data?.availableStar}
                  </h6>
                  <p className="text-sm text-grey font-normal">Star available to Upgrade</p>
                </div>
              </div>
            }
          </div>
          <ProcessingTxnDialog hook={pendingTxDialog} />
          <AppDialog hook={appDialog} />
        </div>
        <Button onClick={processAction} disabled={loading || data?.availableStar <= data?.currentStar} loading={loading} className="rounded-none mt-auto w-full bg-gradient-to-r from-yellowDark to-purpleDark text-white font-semibold text-lg text-left flex justify-between items-center h-14 capitalize">
          Upgrade Star
          <DiamondPlus />
        </Button>
      </div>
    </>
  );
};
export default UpgradeStar;