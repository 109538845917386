import CopyToClipboard from 'react-copy-to-clipboard';
import { Copy, ExternalLink } from 'react-feather';
import toast from 'react-hot-toast';
import { NumericFormat } from 'react-number-format';
import { useUserAuth } from '../Contexts/authContext';
import { Chip } from '@material-tailwind/react';
import appConfig from '../Utility/AppConfig';

export const useHelpers = () => {

    const auth = useUserAuth();

    //Trim an address
    const trimAddress = ({ address, copy = false, scanLink = false, firstShow = 5, secondShow = 3 }) => {
        
        if(address == null) {
            return (<></>);
        }
        const fullAddress = address;

        const short1  = fullAddress.slice(0, firstShow);
        const short2  = fullAddress.slice(fullAddress.length-secondShow, fullAddress.length);
        const trimmedAddr = short1 + '...' + short2;

        if(copy === false) {
            return trimmedAddr;
        } else {
            return (
                <>
                    {trimmedAddr}
                    {copy && <CopyToClipboard onCopy={() => toast.success('Copied!')} text={address}><Copy className='inline-block ml-1' size="13" /></CopyToClipboard>}
                    {scanLink && <a target="_blank" href={appConfig.scanUrl + scanLink}><ExternalLink className='inline-block ml-1' size="13" /></a>}
                </>
            )
        }
        
    }

    //Copy
    const CopyText = ({ value, iconSize = '13' }) => {
        
        return (
            <>
                <CopyToClipboard onCopy={() => toast.success('Copied!')} text={value}>
                    <div className='flex gap-2 items-center'>
                        {value}
                        <Copy size={iconSize} />
                    </div>
                </CopyToClipboard>
            </>
        )
        
    }

    //Convert a amount to readable format
    const AmountToCurrency = ({ amount, decimals = 4, prefix = "", suffix = ' USDT' }) => {
        return (
            <NumericFormat value={amount} displayType="text" allowLeadingZeros allowNegative={true} prefix={prefix} suffix={suffix} thousandsGroupStyle="thousand" decimalScale={decimals} />
        );
    }

    //Convert timestamp to data
    const ProcessDate = (timestamp, opString = false ) => {
        const date = new Date(timestamp*1000);
        const humanData = +date.getDate()+
                "/"+(date.getMonth()+1)+
                "/"+date.getFullYear()+
                " "+date.getHours()+
                ":"+date.getMinutes()+
                ":"+date.getSeconds();

        if(opString) {
            return humanData;
        } else {
            return (
                <>
                {humanData}
                </>
            )
        }
    }
    
    const renderStatusChip = ({ status, color = 'green' }) => {
        let res;
        switch (status) {
            //Campaign status
            case "pending":
                res = <div className='flex justify-center'><Chip size='sm' color='team' variant='outlined' value={status} className='capitalize' /></div>
                break;

            case "waitingClaim":
                res = <div className='flex justify-center'><Chip size='sm' color='purple' variant='gradient' value={status} className='animate-pulse capitalize' /></div>
                break;

            //Campaign status
            case "created":
                res = <div className='flex justify-center'><Chip size='sm' color='amber' variant='outlined' value={status} className='capitalize' /></div>
                break;

            case "received":
                res = <div className='flex justify-center'><Chip size='sm' color='green' variant='gradient' value={status} className='capitalize' /></div>
                break;

            case "completed":
                res = <div className='flex justify-center'><Chip size='sm' color='green' variant='gradient' value={status} className='capitalize' /></div>
                break;

            case "failed":
                res = <div className='flex justify-center'><Chip size='sm' color='red' variant='gradient' value={status} className='capitalize' /></div>
                break;

            case "expired":
                res = <div className='flex justify-center'><Chip size='sm' color='red' variant='outlined' value={status} className='capitalize' /></div>
                break;

            case "active":
                res = <div className='flex justify-center'><Chip size='sm' color='green' variant='gradient' value={status} className='capitalize' /></div>
                break;

            //Payment status
            case "licensePayout":
                res = <div className='flex justify-center'><Chip size='sm' color='amber' variant='gradient' value={status} className='capitalize' /></div>
                break;

            case "fastStartLicense":
                res = <div className='flex justify-center'><Chip size='sm' color='green' variant='gradient' value={status} className='capitalize' /></div>
                break;

            case "rewardsClaimedLicense":
                res = <div className='flex justify-center'><Chip size='sm' color='blue' variant='gradient' value={status} className='capitalize' /></div>
                break;

            case "ResidualMatrixPayout":
                res = <div className='flex justify-center'><Chip size='sm' color='amber' variant='gradient' value={status} className='capitalize' /></div>
                break;

            case "checkmatchLevel1Residual":
                res = <div className='flex justify-center'><Chip size='sm' color='blue' variant='gradient' value={status} className='capitalize' /></div>
                break;

            case "checkmatchLevel2Residual":
                res = <div className='flex justify-center'><Chip size='sm' color='blue-gray' variant='gradient' value={status} className='capitalize' /></div>
                break;

            case "residualFaststart":
                res = <div className='flex justify-center'><Chip size='sm' color='green' variant='gradient' value={status} className='capitalize' /></div>
                break;

            default:
                res = <div className='flex justify-center'><Chip size='sm' color={color} variant='gradient' value={status} className='capitalize' /></div>
                break;

        }
        return res;
    }

    const renderLevelColor = (level) => {
        const colors = [
            "bg-[#000080]", // Dark Blue
            "bg-[#006400]", // Dark Green
            "bg-[#8B0000]", // Dark Red
            "bg-[#FF8C00]", // Dark Orange
            "bg-[#800080]", // Dark Purple
            "bg-[#9400D3]", // Dark Violet
            "bg-[#808080]", // Dark Gray
            "bg-[#008B8B]", // Dark Cyan
            "bg-[#8B008B]", // Dark Magenta
            "bg-[#483D8B]" // Dark Slate Blue
        ];
        return colors[level*1-1];
    }


    const getTokenData = ({ id, response = 'name' }) => {
        try {
            return auth.appData.tokens[id][response];
        } catch (error) {
            return null;
        }
    }

    const getAllTokenSelectOptions = (addNoSelect = false) => {
        try {
            let options = [];
            if(addNoSelect) {
                options.push({
                    value: '',
                    label: addNoSelect
                });
            }
            Object.keys(auth.appData.tokens).forEach(key => {
                const value = auth.appData.tokens[key];
                let option = {
                    'value': value.id,
                    'label': value.name
                };
                options.push(option);
            });
            return options;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    const getChainData = ({ id, response = 'name' }) => {
        try {
            return auth.appData.chains[id][response];
        } catch (error) {
            return null;
        }
    }

    return {
        getChainData,
        getTokenData,
        renderStatusChip, 
        trimAddress, 
        ProcessDate, 
        AmountToCurrency, 
        CopyText,
        renderLevelColor,
        getAllTokenSelectOptions
    };
    
}

