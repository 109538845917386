import React from 'react';
import { Users } from 'react-feather/dist';
import Timestamp from 'react-timestamp';
import useDynamicTable, { DynamicPagination } from '../../Hooks/dynamicTable';
import { useHelpers } from '../../Hooks/helpers';
import { CommonCard, ContentLoading, Title } from '../../Utility/Utility';

const Referrals = () => {

  const helper = useHelpers();

  const { data, loading, currentPage, totalPages, handlePageChange } = useDynamicTable('user/getUserReferrals');
  
  let tableHead = [
    <>Username</>, 
    <>Address</>, 
    <>Licenses Bought</>,
    <>Residual Active</>,
    <>My Earnings</>,
    <>Registered</>
  ];

  return (
    <div className='mt-6'>
      <CommonCard>
        <div className="mb-5 flex justify-between items-center">
            <Title
              title={{ icon: <Users className='w-7 text-purple' />, label: "Referrals" }}
            />
        </div>
        <div className="w-full overflow-scroll px-0 table-custom">
          <table className="w-full">
            <thead>
              <tr>
                {tableHead.map((head, key) => (
                  <th key={key} className="">{head}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {(data.length > 0 && !loading) && data.map(
                (d, index) => {
                  const classes =  "p-4";
                  return (
                    <tr key={index}>
                      <td className={classes}>
                        <helper.CopyText value={d.username} />
                      </td>
                      <td className={classes}>
                        <helper.trimAddress address={d.address} copy/>
                      </td>
                      <td className={classes}>
                        {d.licenses_owned}
                      </td>
                      <td className={classes}>
                        {d.residualActive > 0 ? 'Yes' : 'No'}
                      </td>
                      <td className={classes}>
                        License : <span className='font-bold text-amber-500'><helper.AmountToCurrency amount={d.licensePayments} /></span><br/>
                        Residual : <span className='font-bold text-amber-500'><helper.AmountToCurrency amount={d.residualPayments} /></span>
                      </td>
                      <td className={classes}>
                        <Timestamp date={d.registered} /><br/>
                        <Timestamp date={d.registered} relative className='opacity-60' />
                      </td>
                    </tr>
                  );
                },
              )}
              {loading && 
                <tr>
                  <td colSpan={tableHead.length}><div className='flex justify-center pt-3'><ContentLoading /></div></td>
                </tr>
              }
              {((!data || data.length === 0) && !loading) && 
                <tr>
                  <td colSpan={tableHead.length}><div className='flex justify-center pt-3 opacity-50'>No rows found</div></td>
                </tr>
              }
            </tbody>
          </table>
        </div>
        <DynamicPagination totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
      </CommonCard>
    </div>
  );
};

export default Referrals;