
const runDevAsProd = false;
const settings = {
    useDevServer: (process.env.NODE_ENV === 'development' && !runDevAsProd)?true:false //Change to false for using production servers
}

const appConfig = {
    version: '1.0.6',
    appName: 'DivinePowerOfProsperity',
    appDescription: 'DivinePowerOfProsperity',
    appLink: 'https://app.divinepowerofprosperity.com/',
    websiteLink: 'https://www.divinepowerofprosperity.com/',

    gaID: "G-CTDVL8W4LS",
    walletConnectKey: 'dbc978dc3e2ebf3b04ae10f1a582b3ad',
    walletConnectAllowEndpoint: settings.useDevServer?'http://localhost.3000':'https://app.divinepowerofprosperity.com',

    apiEndpoint: settings.useDevServer?'http://localhost:6480/api/':'https://api.divinepowerofprosperity.com/api/',
    scanUrl: settings.useDevServer?'https://holesky.etherscan.com/':'https://www.polygonscan.com/',
    
    telgramBotLink: 'https://t.me/DivinePowerOfProsperityBot',

    //URLs
    support: 'https://divinepower.freshdesk.com/support/tickets/new',
    payLink: 'https://pay.dex3.io/pay/',
    payoutLink: 'https://pay.dex3.io/payout/',
    
}

export default appConfig;