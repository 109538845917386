import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router } from "react-router-dom";
import { bsc, bscTestnet, holesky, mainnet, polygon } from 'viem/chains';
import { WagmiProvider } from 'wagmi';
import ShowAd from './Components/layout/ShowAd';
import ScrollToTop from './Contexts/ScrollToTop';
import { AppStateProvider } from './Contexts/appStateContext';
import { UserAuthProvider } from './Contexts/authContext';
import Layout from './Routers/Layout';
import appConfig from './Utility/AppConfig';

function App() {
  const queryClient = new QueryClient();
  const projectId = appConfig.walletConnectKey;

  const metadata = {
    name: appConfig.appName,
    description: appConfig.appDescription,
    url: appConfig.walletConnectAllowEndpoint,
    icons: ['https://i.ibb.co/ypqmwz6/cropped-New-Project.png']
  };

  const chains = [ mainnet, bsc, polygon, holesky, bscTestnet ];

  const config = defaultWagmiConfig({
    chains, // required
    projectId, // required
    metadata, // required
    enableWalletConnect: true, // Optional - true by default
    enableInjected: true, // Optional - true by default
    enableEIP6963: true, // Optional - true by default
    enableCoinbase: true, // Optional - true by default
    // enableEmail: true
  });

  createWeb3Modal({
    wagmiConfig: config,
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    themeMode: 'dark',
    themeVariables: {
      "--w3m-accent": '#C58E00'
    }
  });

  return (
    <AppStateProvider>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <UserAuthProvider>
            <Router>
              <ScrollToTop />
              <Layout />
            </Router>
            <Toaster position="bottom-center" />
            <ShowAd />
          </UserAuthProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </AppStateProvider>
  );
}
export default App;
