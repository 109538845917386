import React from 'react';
import { Users } from 'react-feather';
import { CommonCard, TitlePrimary, TitleSecondary } from '../../../Utility/Utility';

const LicenseDownlines = ({ downlines }) => {

  let tableHead = [
    <>Level</>, 
    <>Downlines</>
  ];

  return (
    <>
    <h3 className='font-bold text-white text-md mb-4'>License Downlines</h3>
      <div className="w-full overflow-scroll px-0 table-custom">
        <table className="w-full">
          <thead>
            <tr>
              {tableHead.map((head, key) => (
                <th key={key} className="">{head}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {downlines.map(
              (d, index) => {
                const classes =  "p-4";
                return (
                  <tr key={index}>
                    <td className={classes}>
                      Level {d.level}
                    </td>
                    <td className='font-bold flex justify-end items-center text-blue-400'>
                      <Users className='w-4 mr-1' /> {d.count}
                    </td>
                  </tr>
                );
              },
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default LicenseDownlines;