import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAccount, useWalletClient } from 'wagmi';
import { useUserAuth } from '../../Contexts/authContext';
import { useCallAPI } from '../../Hooks/callAPI';
import { useDeviceData } from '../../Hooks/deviceData';
import { useWrite } from '../../Hooks/writeContract';
import { TitlePrimary } from "../../Utility/Utility";

const Login = () => {

    const callAPI = useCallAPI();
    const auth = useUserAuth();
    const account = useAccount();
    const writeContract = useWrite();
    const device = useDeviceData();
    const params = useParams();
    const wallet = useWalletClient();
    const navigate = useNavigate();
    
    const [connectAddr, setConnectAddr] = useState(account.address);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setConnectAddr(account.address);
    }, [account.address]);

    useEffect(() => {
      if(wallet?.data?.account?.address && auth.loggedIn === false && params.login === 'yes') {
        loginNow();
      }
    }, [auth.loggedIn, wallet?.data?.account?.address]);

    const loginNow = async () => {
      try {

        setLoading(true);
        const res = await callAPI("auth/getloginnonce", { address: connectAddr });
        const signature = await writeContract.SignMessage(res.nonce, connectAddr);
        
        const pres = await callAPI("auth/completeLogin", { hash: signature, address: connectAddr });
        device.setToken(pres.token);
        await auth.bootApp();
        setLoading(false);

      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }

    return (
      <>
        <TitlePrimary>Login Now</TitlePrimary>
        <div className="space-y-5 md:space-y-6">
          <p className="text-white/70 text-base">
            {!account.isConnected ?
              <>Connect your wallet 👇</>
            :
              <>Wallet Connected 👍</>
            }
          </p>
          <w3m-button />
          <p className="text-white/70 text-base">
            Sign a nonce request from your wallet to authenticate. <br/>You will not spend any BNB to login.
          </p>
          <Button 
          loading={loading}
          disabled={account.isConnected !== true || loading}
          onClick={loginNow}
          className="bg-gradient-to-r from-yellowLight to-yellowDark text-darker text-xl capitalize font-bold rounded-lg px-10 sm:px-20"
          >
            Login Now
          </Button>
          <p onClick={() => navigate('/register')} className="text-grey text-sm cursor-pointer">
            New here? Register Now
          </p>
        </div>
      </>
    );
};

export default Login;
