import React from "react";
import AppRouter from "../../Routers/AppRouter";
import DashFooter from "./DashFooter";
import Header from "./Header";
import Sidebar from "./Sidebar";
import BannerBox from "./BannerBox";

const DashboardLayout = () => {
  return (
    <div className="bg-gradient-to-r from-black to-[#191919] min-h-screen">
      <div className="hidden xl:block fixed h-screen left-0 top-0">
        <Sidebar />
      </div>
      <div className="xl:ml-[320px]">
        <Header />
        <div className="px-4 lg:px-6 space-y-4 lg:space-y-6">
          <div className="flex justify-center mt-5"><BannerBox /></div>
          <AppRouter />
          <div className="flex justify-center"><BannerBox /></div>
          <DashFooter />
        </div>
      </div>
    </div>
  )
};

export default DashboardLayout;
