import { useEffect, useState } from "react";
import PurchaseBlock from "../../Components/dash/purchase/PurchaseBlock";
import PurchaseHistory from "../../Components/dash/purchase/PurchaseHistory";
import { useUserAuth } from "../../Contexts/authContext";

const Purchase = () => {

  const auth = useUserAuth();

  const [historyKey, setHistoryKey] = useState(1);

  useEffect(() => {
    setHistoryKey(historyKey+1);
  }, [auth]);

  return (
    <div className="xl:grid grid-cols-[30%_70%] xl:space-x-5 space-y-5 xl:space-y-0 my-6">
      <div><PurchaseBlock /></div>
      <PurchaseHistory key={historyKey} />
    </div>
  );
};
export default Purchase;