import { ShoppingBagIcon } from '@heroicons/react/24/outline';
import { Button, Collapse } from '@material-tailwind/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Timestamp from 'react-timestamp';
import { useCallAPI } from '../../../Hooks/callAPI';
import useDynamicTable, { DynamicPagination } from '../../../Hooks/dynamicTable';
import { useHelpers } from '../../../Hooks/helpers';
import { FormDate, FormInput } from '../../../Utility/Inputs';
import { ContentLoading, Title } from '../../../Utility/Utility';

const TransferHistoryReceived = () => {

  const helper = useHelpers();
  const callAPI = useCallAPI();

  const [showSearch, setShowSearch] = useState(false);

  let defaults = {
    order_id: '',
    pay_hash: '',
    oracle_hash: '',
    status: ''
  }
  const [searchFilters, setSearchFilters] = useState(defaults);

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const handleSearchSubmit = () => {
    try {
      if(fromDate !== '') {
        if(toDate === '') { throw 'Till date is required' }
        if(Math.floor(Date.parse(fromDate) / 1000) > Math.floor(Date.parse(toDate) / 1000)) { throw 'Till date cannot be smaller than from date' }
      }
      setSearchFilters(getValues());
      handlePageChange(1); // Reset to first page when searching
    } catch (error) {
      toast.error(error);
    }
  };

  const handleReset = () => {
    reset();
    setSearchFilters(defaults);
    setFromDate('');
    setToDate('');
    handlePageChange(1);
  };

  const { data, loading, currentPage, totalPages, handlePageChange } = useDynamicTable('user/getReceivedTransfers', fromDate, toDate, searchFilters);

  let tableHead = [
    <>From Username</>,
    <>Amount</>,
    <>Hash</>,
    <>Date</>
  ];

  const {
      register, reset, formState: { errors, isValid, isDirty }, handleSubmit, getValues, setValue, control, watch
  } = useForm({ mode: 'onChange' })
  
  return (
    <>
      <div className="mb-5 flex justify-between items-center">
        <Title
          title={{ icon: <ShoppingBagIcon className='w-7 text-amber-400' />, label: "Funds Received" }}
        />
        <Button size='sm' color='amber' variant='gradient' onClick={() => setShowSearch(!showSearch)}>{!showSearch ? 'Search' : 'Hide'}</Button>
      </div>
      <Collapse open={showSearch}>
        <div className='mb-5 border-4 border-[#7b7b7b]/30 px-2 py-5 rounded-10'>
          <form onSubmit={handleSubmit(handleSearchSubmit)}>
            <div className='flex flex-wrap justify-center gap-3'>
              <FormInput name="from_username" placeholder="From Username" reg={register} formerror={false} errors={errors} isdirty={isDirty ? 1 : 0} />
              <FormInput name="amount" placeholder="Amount" reg={register} formerror={false} errors={errors} isdirty={isDirty ? 1 : 0} />
              <FormInput name="hash" placeholder="Transaction Hash" reg={register} formerror={false} errors={errors} isdirty={isDirty ? 1 : 0} />
              <FormDate name="fromDate" setDate={setFromDate} date={fromDate} placeholder="From Date" errors={errors} />
              <FormDate name="toDate" setDate={setToDate} date={toDate} placeholder="Till Date" errors={errors} />
            </div>
            <div className='flex justify-center mt-4 gap-2'>
              <Button type='submit' color='blue' variant='gradient' disabled={loading} loading={loading}>Search</Button>
              <Button type='submit' color='red' variant='outlined' onClick={handleReset}>Reset</Button>
            </div>
          </form>
        </div>
      </Collapse>
      <div className="w-full overflow-scroll px-0 table-custom">
        <table className="w-full">
          <thead>
            <tr>
              {tableHead.map((head, key) => (
                <th key={key} className="">{head}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {(data.length > 0 && !loading) && data.map(
              (d, index) => {
                const classes =  "p-4";
                return (
                  <tr key={d.payment_id}>
                    <td className={classes}>
                      <helper.CopyText value={d.to_username} />
                    </td>
                    <td className={classes}>
                      <helper.AmountToCurrency amount={d.amount} />
                    </td>
                    <td className={classes}>
                      <helper.trimAddress address={d.hash} copy  scanLink={'tx/' + d.hash}/>
                    </td>
                    <td className={classes}>
                      <Timestamp date={d.created} /><br/>
                      <Timestamp date={d.created} relative className='opacity-60' />
                    </td>
                  </tr>
                );
              },
            )}
            {loading && 
              <tr>
                <td colSpan={tableHead.length}><div className='flex justify-center pt-3'><ContentLoading /></div></td>
              </tr>
            }
            {((!data || data.length === 0) && !loading) && 
              <tr>
                <td colSpan={tableHead.length}><div className='flex justify-center pt-3 opacity-50'>No rows found</div></td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <DynamicPagination totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
    </>
  );
};

export default TransferHistoryReceived;