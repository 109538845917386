import { ExternalLink } from "lucide-react";

const HowToStart = () => {

    return (
      <a href="">
        <div className="flex justify-between items-center bg-gradient-to-r from-[#555] to-[#333] rounded-lg mt-4 p-3 px-4 text-white font-bold text-md">
          <p>Click here to learn how to get started.</p>
          <ExternalLink size={20} />
        </div>
      </a>
    );
};

export default HowToStart;
