import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthLayout from "../Components/layout/Auth";
import DashboardLayout from "../Components/layout/Dashboard";
import { useUserAuth } from '../Contexts/authContext';
import LayoutLoading from "./LayoutLoading";

export default function Layout() { 

    const auth = useUserAuth();
    const navigate = useNavigate();
    const location = useLocation();

    //Check for authentication and redirect to login if not logged in
    useEffect(() => {
        if(auth.appBooted === true && auth.loggedIn !== true && (!location.pathname.includes('/login') && !location.pathname.includes('/register') && !location.pathname.includes('/splash') && !location.pathname.includes('/ref'))) {
            navigate('/splash');
            return;
        }
        if(auth.appBooted === true && auth.loggedIn === true && (location.pathname.includes('/login') || location.pathname.includes('/register') || location.pathname.includes('/splash') || location.pathname.includes('/ref'))) {
            navigate('/dashboard');
            return;
        }
    }, [auth]);
      
    return (
        <>
            {auth.appBooted && auth.loggedIn === false && <AuthLayout />}
            {auth.appBooted && auth.loggedIn === true && <DashboardLayout />}
            {!auth?.appBooted && <LayoutLoading />}
        </>
    );
}