import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from "@material-tailwind/react";
import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import { toast } from "react-hot-toast";
import * as Yup from 'yup';
import { useCallAPI } from '../../../Hooks/callAPI';
import { FormInput } from '../../../Utility/Inputs';

const CreateNewBannerAd = ({ auth, refresh }) => {

  const callAPI = useCallAPI();

  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState({});

  const validationSchema = Yup.object().shape({
    link: Yup.string().url().required('Please enter a valid link'),
    banner: Yup.string().url().required('Please enter a valid link'),
  });

  const {
      register, formState: { errors, isValid, isDirty }, handleSubmit, getValues, setValue, control, watch
  } = useForm({ mode: 'onChange', resolver: yupResolver(validationSchema) })

  const onSubmit = async () => {
    try {

      setLoading(true);
      setFormError({});

      const formData = getValues();
      const res = await callAPI("user/createBannerAd", formData);
      await auth.bootApp();
      toast.success('New ad created successfully');
      refresh();

      setLoading(false);

    } catch (error) {
      if(error.code && error.code === 403) { //Handle form error
          setFormError(error.errData);
      }
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="px-2 space-y-5">
        <FormInput name='link' label="Website Link" reg={register} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
        <FormInput name='banner' label="Banner URL" reg={register} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
        <FormInput name='banner_credits' label="Credits to add" reg={register} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
        <Button type="submit" loading={loading} disabled={!isValid || !isDirty || loading} className="bg-[#04D88C] mt-3">Create Now</Button>
      </div>
    </form>
  );
};

export default CreateNewBannerAd;