import { Button } from "@material-tailwind/react";
import { AlertOctagon, Blocks } from "lucide-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Timestamp from "react-timestamp";
import { useUserAuth } from "../../../Contexts/authContext";
import { useAppDialog } from "../../../Hooks/appDialog";
import { useCallAPI } from "../../../Hooks/callAPI";
import { useWaitTransaction } from "../../../Hooks/waitTransaction";
import { TitlePrimary } from "../../../Utility/Utility";
import { AppDialog } from "../../modals/AppDialog";
import { ProcessingTxnDialog } from "../../modals/ProcessingTxnDialog";

const ActivateResidual = ({ data, refreshData }) => {

  const auth = useUserAuth();
  const callAPI = useCallAPI();
  const navigate = useNavigate();
  const waitTransaction = useWaitTransaction();
  const pendingTxDialog = useAppDialog();
  const appDialog = useAppDialog();

  const [cost, setCost] = useState(149);
  const [loading, setLoading] = useState(false);

  const activate = async () => {
    try {
      setLoading(true);
      const hash = await callAPI('user/activateResidual');

      //Wait for transaction
      await waitTransaction.wait(hash.hash, 'Residual Matrix Activated Successfully', pendingTxDialog, appDialog);
      await refreshData('yes');
      await auth.bootApp();
      setLoading(false);
      
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const renew = async () => {
    try {
      setLoading(true);
      const hash = await callAPI('user/renewResidual');

      //Wait for transaction
      await waitTransaction.wait(hash.hash, 'Residual Matrix Renewed Successfully', pendingTxDialog, appDialog);
      await refreshData('yes');
      await auth.bootApp();
      setLoading(false);
      
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <>
      <div className="p-6">
          <TitlePrimary>Residual Matrix <Button size="sm" className="py-[3px] px-2 ml-3 text-xs capitalize" color={data.expiration*1000 > Date.now() ? 'green' : 'red'}>{data.expiration*1000 > Date.now() ? 'Active' : 'Inactive'}</Button></TitlePrimary>
        <div>
          <div>
            <h6 className="bg-gradient-to-r from-yellowLight to-yellowDark bg-clip-text text-transparent text-[22px] font-bold">
              ${cost}
            </h6>
            <p className="text-sm text-grey font-normal">Per Month</p>
          </div>
          {data?.residualId*1 > 0 &&
            <div className="mt-6">
              <h6 className="bg-gradient-to-r from-yellowLight to-yellowDark bg-clip-text text-transparent text-[18px] font-bold">
                <Timestamp date={data.expiration*1} relative />
              </h6>
              <p className="text-sm text-grey font-normal">Expires in</p>
            </div>
          }
        </div>
        {auth?.appData.user.balance*1 < cost &&
          <div className="mt-6 bg-red-800 text-white p-2 pl-3 rounded-md animate-pulse flex items-center justify-between gap-2">
            <div className="flex justify-start gap-2 items-center">
              <AlertOctagon size={16} />
              <p className="leading-lg text-sm font-bold text-white">Insufficient balance</p>
            </div>
            <Button onClick={() => navigate('/purchase')} color="black" variant="gradient" size="sm">
              Add
            </Button>
          </div>
        }
      </div>
      {data?.residualId*1 === 0 ? 
        <Button onClick={activate} disabled={loading || auth?.appData.user.balance*1 < cost} loading={loading} className="rounded-none mt-auto w-full bg-gradient-to-r from-yellowDark to-yellowLight text-white font-semibold text-lg text-left flex justify-between items-center h-14 capitalize">
          Activate Now
          <Blocks />
        </Button>
      :
        <Button onClick={renew} disabled={loading || auth?.appData.user.balance*1 < cost} loading={loading} className="rounded-none mt-auto w-full bg-gradient-to-r from-yellowDark to-yellowLight text-white font-semibold text-lg text-left flex justify-between items-center h-14 capitalize">
          Extend 30 more days
          <Blocks />
        </Button>
      }
    <ProcessingTxnDialog hook={pendingTxDialog} />
    <AppDialog hook={appDialog} />
    </>
  );
};
export default ActivateResidual;
