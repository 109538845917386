import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import toast from "react-hot-toast";
import { useCallAPI } from "./callAPI";
import { usePollAPI } from "./pollAPI";

export const useWaitWithdrawal = () => {

    const callAPI = useCallAPI();
    const pollResults = usePollAPI();
        
    const waitProcess = (order_id, pendingDialog) => {

        pendingDialog.open({
            header: 'Processing Withdrawal',
            description: 'Please be patient. This should take 0-2 minutes.',
        });

        return new Promise(async (resolve, reject) => {
            try {
                const apiToPoll = () => callAPI("user/checkWithdrawalTxn/" + order_id);
                const pollContinueCondition = (res) => res.status === "pending" || res.status === "received" || res.status === "processing";
                const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
                pendingDialog.close();

                if(pollRes.status == "waitingClaim") {
                    return resolve(true);
                } else if(pollRes.status == "failed") {
                    toast.error("Withdrawal Transaction Failed");
                    return reject(false);
                }
            } catch (error) {
                toast.error("Withdrawal Transaction Failed to initiate");
                console.log(error.message);
                reject(false);
            }
        });
    }

    const waitClaim = (order_id, openLink, waitingClaimDialog, appDialog) => {

        waitingClaimDialog.open({
            header: 'Processing Claim..',
            description: 'Please wait while the claim transaction is being processed. This should take 0-2 minutes.',
            linkType: 'claim',
            backdropDismiss: true,
            openLink: openLink,
            order_id: order_id
        });

        return new Promise(async (resolve, reject) => {
            try {
                const apiToPoll = () => callAPI("user/checkWithdrawalTxn/" + order_id);
                const pollContinueCondition = (res) => res.status === "waitingClaim";
                const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
                waitingClaimDialog.close();

                if(pollRes.status == "completed") {
                    appDialog.open({
                        icon: <CheckBadgeIcon className="w-[40px] text-green-400" />,
                        header: `Donations Claimed Successfully!`,
                        description: 'Your claimed donations have been transferred to your registered wallet.',
                        backdropDismiss: true,
                        buttons: [
                            {
                                text: 'Continue',
                                color: 'blue'
                            }
                        ]
                    });
                    return resolve(true);
                } else if(pollRes.status == "failed") {
                    toast.error("Claim Failed. Please contact support");
                    return reject(false);
                }
            } catch (error) {
                console.log(error.message);
                reject(false);
            }
        });
    }

    return { waitClaim, waitProcess };

};