import { Button } from "@material-tailwind/react";
import { Download, PlusSquare } from "react-feather";
import { useNavigate } from "react-router-dom";
import link from "../../Assets/link.svg";
import { useUserAuth } from "../../Contexts/authContext";
import { useHelpers } from "../../Hooks/helpers";
import appConfig from "../../Utility/AppConfig";
import { SidebarWithBurgerMenu } from "./SidebarWithBurgerMenu";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { ArrowBigDownDash, PlusSquareIcon, TicketPlus } from "lucide-react";

const Header = () => {

  const navigate = useNavigate();
  const helper = useHelpers();
  const auth = useUserAuth();

  return (
    <header className="lg:h-[80px] flex flex-col-reverse lg:flex-row justify-between lg:items-center bg-[#222222]">
      <div className="grid grid-cols-[auto_180px] lg:grid-cols-[300px_200px] h-12 lg:h-full">
        <div className="bg-black h-full flex items-center px-4 lg:px-6">
          <p className="text-[#a2a3b1] text-sm font-normal flex items-center gap-3 w-[150px] md:w-full">
            <img src={link} alt="link" />
            <span className="block w-full whitespace-nowrap text-ellipsis overflow-hidden">
              {appConfig.appLink + 'ref/' + auth.appData.user.username}
            </span>
          </p>
        </div>
        <CopyToClipboard text={appConfig.appLink + 'ref/' + auth.appData.user.username} onCopy={() => toast.success('Copied to clipboard')}>
          <Button className="h-full bg-gradient-to-r from-purpleLight to-purpleDark rounded-none text-sm capitalize font-semibold">
            Copy invitation Link
          </Button>
        </CopyToClipboard>
      </div>
      <div className="grid grid-cols-[auto_70px_70px] sm:grid-cols-[auto_80px_80px] lg:grid-cols-[auto_100px_100px] h-[70px] sm:h-[74px] lg:h-full">
        <div className="flex items-center">
          <div className="bg-black h-full w-[70px] sm:w-20 flex items-center justify-center xl:hidden">
            <SidebarWithBurgerMenu />
          </div>
          <div className="flex flex-col justify-center px-4 lg:px-6">
            <p className="text-gray-500 text-sm font-normal">
              <span className="hidden sm:inline-block">Available</span> Balance
            </p>
            <strong className="text-white font-bold text-[20px] leading-tight mt-[1px]">
              <helper.AmountToCurrency amount={auth?.appData?.user?.balance} suffix="" /><span className="text-xs text-grey ml-1">USDT</span>
            </strong>
          </div>
        </div>
        <Button onClick={() => navigate('/purchase')} className="text-sm h-full flex flex-col justify-center gap-2 bg-black rounded-none items-center capitalize p-2 ">
          <TicketPlus />
          Add
        </Button>
        <Button onClick={() => navigate('/withdraw')} className="text-sm h-full flex-col gap-2 flex items-center bg-gradient-to-r from-yellowLight to-yellowDark rounded-none capitalize justify-center font-semibold p-2">
          <ArrowBigDownDash />
          Withdraw
        </Button>
      </div>
    </header>
  );
};

export default Header;
