import { BarLoader, BounceLoader, ClipLoader } from 'react-spinners';
export const TitlePrimary = ({ prefix, children }) => {
  return (
    <h2 className="text-white text-lg leading-5 font-bold border-b-2 border-dashed border-grey/40 pb-4 mb-7 flex items-center">
      <img
        src={prefix ? prefix : ""}
        alt=""
        className={prefix ? "mr-2.5" : ""}
      />
      {children}
    </h2>
  );
};
export const CmnCard = ({ bg, children }) => {
  return (
    <div
      style={{ backgroundColor: bg ? bg : "#222222" }}
      className="px-4 py-5 md:p-6"
    >
      {children}
    </div>
  );
};


export const TitleSecondary = ({ variant, children }) => {
  let classes;
  switch (variant) {
    case "light-blue":
      classes = "bg-[#B1E5FC]";
      break;
    case "light-orange":
      classes = "bg-[#FFBC99]";
      break;
    default:
      classes = "bg-[#B1E5FC]";
  }
  return (
    <h2 className="text-[#1A1D1F] text-xl font-semibold inline-flex items-center">
      <span
        className={`${classes} w-4 h-8 rounded-sm inline-block mr-3`}
      ></span>
      {children}
    </h2>
  );
};
export const CommonCard = ({ children }) => {
  return (
    <div className="bg-[#222222] rounded-lg p-6">
      {children}
    </div>
  );
};

export const Title = ({ title }) => {
  return (
    <h3 className="flex items-center gap-2 text-white text-lg font-bold">
      {title.icon}
      {title.label}
    </h3>
  );
};
export const TitleTwo = ({ children }) => {
  return (
    <h2 className="text-[#01161E] font-bold text-[20px] md:text-2xl xl:text-[30px] mb-2 xl:mb-5 leading-[1.3]">
      {children}
    </h2>
  );
};

export const LoadingData = ({ fullHeight = true }) => {
  return (
      <div className={`${fullHeight ? 'grid place-items-center py-[50px] sm:py-[100px]' : 'm-auto'}`}>
        <BounceLoader
          color='#fff'
          loading={true}
          size={40}
          aria-label="Loading"
          data-testid="loader"
        />
      </div>
  )
}

export const ContentLoading = ({ ...props }) => {
  return (
      <div {...props}>
        <BarLoader
          color='#999'
          speedMultiplier={0.8}
          loading={true}
          size={props.loaderSize ? props.loaderSize : '30px'}
          aria-label="Loading"
          data-testid="loader"
        />
      </div>
  )
}

export const EmptyRecords = ({ title, description, buttonHandler = false, fullHeight = false }) => {
  return (
    <div className={`grid place-items-center ${fullHeight ? 'h-screen' : 'py-[50px] sm:py-[100px]'}`}>
      <div className="max-w-[250px] text-center p-5 bg-white shadow-md rounded-10">
        <p className="text-gray-800 font-bold text-md leading-5 my-2">{title}</p>
        <p className="text-gray-600 text-sm leading-4 mt-1">{description}</p>
        {buttonHandler && <button className="bg-gradient-two px-5 py-2 rounded-[5px] font-bold text-sm mt-4" onClick={buttonHandler.onClick}>{buttonHandler.text}</button>}
      </div>
    </div>
  )
}