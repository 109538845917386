import { Button } from "@material-tailwind/react";
import React, { useEffect } from 'react';
import toast from "react-hot-toast";
import { useNavigate, useParams } from 'react-router-dom';
import { TitlePrimary } from "../../Utility/Utility";

const Splash = () => {

    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
      if(params.ref) {
        localStorage.setItem("referrerUsername", params.ref);
        toast.success("Referred by " + params.ref);
      } else {
        if(localStorage.getItem("referrerUsername") !== null && localStorage.getItem("referrerUsername") !== undefined) {
            toast.success("Referred by " + localStorage.getItem("referrerUsername"));
        }
      }
    }, []);
    
    return (
      <>
        <TitlePrimary>Welcome 👋</TitlePrimary>
        <div className="space-y-5 md:space-y-6">
          <p className="text-white/70 text-base">
            New here? Let's create an account first. ✍️
          </p>
          <Button 
            onClick={() => navigate('/register')}
            className="bg-gradient-to-r from-purpleLight to-purpleDark text-white text-lg capitalize font-bold rounded-lg px-10 sm:px-20"
          >
            Register Now
          </Button>
          <p className="text-white/70 text-base">
            Already have an account? Let's login.
          </p>
          <Button 
            onClick={() => navigate('/login')}
            className="bg-gradient-to-r from-yellowLight to-yellowDark text-darker text-lg capitalize font-bold rounded-lg px-10 sm:px-20"
          >
            Login Now
          </Button>
        </div>
      </>
    );
};

export default Splash;
