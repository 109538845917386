import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../Contexts/authContext";
import { useCallAPI } from "../../Hooks/callAPI";

const BannerBox = () => {

  const callAPI = useCallAPI();
  const auth = useUserAuth();

  const [banner, setBanner] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAd();
  }, []);

  const getAd = async () => {
    try {
      
      if(!auth.loggedIn) return;

      const res = await callAPI("user/getBannerAd");
      if(res) {
        setBanner(res)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const recordConversion = async () => {
    try {
      setLoading(true);
      const res = await callAPI("user/recordBannereAdConversion/" + banner.id);
      window.open(res, '_blank');
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }
  
  return (
    <>
      {banner && <img src={banner.banner} alt="Banner Ad" className={`w-[728px] h-[90px] ${loading ? 'pointer-events-none cursor-wait' : 'cursor-pointer'}`} onClick={recordConversion} />}
    </>
  );
};

export default BannerBox;