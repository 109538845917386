import { CircleSlash } from 'lucide-react';
import React from 'react';
import Timestamp from 'react-timestamp';
import { useHelpers } from '../../../Hooks/helpers';
import { CommonCard, TitlePrimary } from '../../../Utility/Utility';

const LicenseData = ({ licenseData }) => {

  const helper = useHelpers();

  let tableHead = [
    <>License #</>, 
    <>Upline License #</>, 
    <>Earnings Start</>,
    <>Total Claimed</>,
    <>Status</>,
    <>Purchased On</>,
  ];

  const classes =  "p-4";

  return (
    <div className="w-full overflow-scroll px-0 table-custom">
        <table className="w-full">
        <thead>
            <tr>
            {tableHead.map((head, key) => (
                <th key={key} className="">{head}</th>
            ))}
            </tr>
        </thead>
        <tbody>
            <tr>
                <td className={classes}>
                    {licenseData.license_id}
                </td>
                <td className={classes}>
                    {licenseData.upline_id}
                </td>
                {licenseData.startTime*1 > 0 ?
                <td className={classes}>
                    <Timestamp date={licenseData.startTime} /><br/>
                    <Timestamp date={licenseData.startTime} relative className='opacity-60' />
                </td>
                :
                <td className='flex justify-center'>
                    <CircleSlash size={15} className='mt-[10px]' />
                </td>
                }
                <td className={classes}>
                <helper.AmountToCurrency amount={licenseData.totalClaimed} />
                </td>
                {licenseData.isExpired ?
                <td className={classes}>
                    <helper.renderStatusChip status='Expired' color='red' />
                </td>
                : licenseData.startTime*1 > 0 ?
                <td className={classes}>
                    <helper.renderStatusChip status='Active' />
                </td>
                :
                <td className={classes}>
                    <helper.renderStatusChip status='InActive' color='black' />
                </td>
                }
                <td className={classes}>
                <Timestamp date={licenseData.created} /><br/>
                <Timestamp date={licenseData.created} relative className='opacity-60' />
                </td>
            </tr>
        </tbody>
        </table>
    </div>
  );
};

export default LicenseData;