import { Button } from "@material-tailwind/react";
import { AlertOctagon, Anvil } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../../Contexts/authContext";
import { useAppDialog } from "../../../Hooks/appDialog";
import { useCallAPI } from "../../../Hooks/callAPI";
import { useWaitTransaction } from "../../../Hooks/waitTransaction";
import { TitlePrimary } from "../../../Utility/Utility";
import { AppDialog } from "../../modals/AppDialog";
import { ProcessingTxnDialog } from "../../modals/ProcessingTxnDialog";

const PurchaseLicense = ({ refreshData }) => {

  const auth = useUserAuth();
  const callAPI = useCallAPI();
  const navigate = useNavigate();
  const waitTransaction = useWaitTransaction();
  const pendingTxDialog = useAppDialog();
  const appDialog = useAppDialog();

  const [count, setCount] = useState(1);
  const [cost, setCost] = useState(50);
  const [loading, setLoading] = useState(false);

  const purchase = async () => {
    try {
      setLoading(true);
      const hash = await callAPI('user/purchaseLicenses', { count: count });

      //Wait for transaction
      await waitTransaction.wait(hash.hash, 'Purchase Completed Successfully', pendingTxDialog, appDialog);
      await auth.bootApp();
      await refreshData('yes');
      setLoading(false);
      
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const handleCounter = (add) => {
    if(add) {
      setCount(count+1);
    } else {
      count > 1 && setCount(count-1);
    }
  }

  return (
    <>
      <div className="bg-[#222222]  flex flex-col h-full">
        <div className="p-6">
          <TitlePrimary>Purchase Licences</TitlePrimary>
          <div>
            <div>
              <h6 className="bg-gradient-to-r from-yellowLight to-yellowDark bg-clip-text text-transparent text-[22px] font-bold">
                ${cost}
              </h6>
              <p className="text-base text-grey font-normal">Per License</p>
            </div>
            <div className="mt-7">
              <Button onClick={() => handleCounter(false)} className="rounded-none w-[36px] h-[36px] p-0 inline-flex items-center justify-center text-3xl text-red-400">
                -
              </Button>
              <input
                type="text"
                value={count}
                className="bg-[#161616] h-[36px] w-[40px] text-center text-white text-base focus:outline-none "
              />
              <Button onClick={() => handleCounter(true)} className="rounded-none w-[36px] h-[36px] p-0 inline-flex items-center justify-center text-3xl text-blue-400">
                +
              </Button>
            </div>
          </div>
          {auth?.appData.user.balance*1 < cost * count &&
            <div className="mt-6 bg-red-800 text-white p-2 pl-3 rounded-md animate-pulse flex items-center justify-between gap-2">
              <div className="flex justify-start gap-2 items-center">
                <AlertOctagon size={16} />
                <p className="leading-lg text-sm font-bold text-white">Insufficient balance</p>
              </div>
              <Button onClick={() => navigate('/purchase')} color="black" variant="gradient" size="sm">
                Add
              </Button>
            </div>
          }
        </div>
        <Button onClick={purchase} disabled={loading || auth?.appData.user.balance*1 < cost * count} loading={loading} className="rounded-none mt-auto w-full bg-gradient-to-r from-purpleDark to-purpleLight text-white font-semibold text-lg text-left flex justify-between items-center h-14 capitalize">
          Purchase Now
          <Anvil />
        </Button>
      </div>
      <ProcessingTxnDialog hook={pendingTxDialog} />
      <AppDialog hook={appDialog} />
    </>
  );
};
export default PurchaseLicense;
