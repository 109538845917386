import {
  Card,
  List,
  ListItem,
  ListItemPrefix
} from "@material-tailwind/react";
import { Anvil, ArrowBigDownDash, ArrowLeftRight, BadgeDollarSign, BotMessageSquare, Home, Image, SquareGanttChart, SquareLibrary, Table2, UsersRound } from "lucide-react";
import React from "react";
import { LogOut } from "react-feather";
import { Link } from "react-router-dom";
import logo from "../../Assets/logo.png";
import { useUserAuth } from "../../Contexts/authContext";
import { useDeviceData } from "../../Hooks/deviceData";
import appConfig from "../../Utility/AppConfig";

const Divider = ({title}) => {
  return (
    <div className="px-8 py-2 font-bold text-xs uppercase tracking-widest">{title}</div>
  )
}

const Sidebar = ({ close }) => {

  const device = useDeviceData();
  const auth = useUserAuth();

  return (
    <Card className="sidebar h-screen w-[300px] xl:w-[320px] bg-[#161616] rounded-none shadow-none overflow-auto">
      <div className="mb-6 mt-7 px-3">
        <img className="w-[280px] mx-auto" src={logo} alt="brand" />
      </div>
      <List className="p-0 sidebar-list mb-5">
        <Link to={'/dashboard'} onClick={close}>
          <ListItem className="list-item">
            <ListItemPrefix>
              <Home className="w-5 text-yellowLight" />
            </ListItemPrefix>
            Dashboard
          </ListItem>
        </Link>
        <Divider title='Finance' />
        <Link to={'/purchase'} onClick={close}>
          <ListItem className="list-item">
            <ListItemPrefix>
            <BadgeDollarSign className="w-5 text-yellowLight" />
            </ListItemPrefix>
            Deposit Balance
          </ListItem>
        </Link>
        <Link to={'/withdraw'} onClick={close}>
          <ListItem className="list-item">
            <ListItemPrefix>
            <ArrowBigDownDash className="w-5 text-yellowLight" />
            </ListItemPrefix>
            Withdraw Balance
          </ListItem>
        </Link>
        <Link to={'/transfer'} onClick={close}>
          <ListItem className="list-item">
            <ListItemPrefix>
            <ArrowLeftRight className="w-5 text-yellowLight" />
            </ListItemPrefix>
            Transfer Balance
          </ListItem>
        </Link>
        <Divider title='Licenses' />
        <Link to={'/licenses'} onClick={close}>
          <ListItem className="list-item">
            <ListItemPrefix>
            <Anvil className="w-5 text-yellowLight" />
            </ListItemPrefix>
            My Licenses
          </ListItem>
        </Link>
        <Link to={'/allLicensePayments'} onClick={close}>
          <ListItem className="list-item">
            <ListItemPrefix>
            <SquareLibrary className="w-5 text-yellowLight" />
            </ListItemPrefix>
            License Payments
          </ListItem>
        </Link>
        <Divider title='Residual' />
        <Link to={'/allResidualPayments'} onClick={close}>
          <ListItem className="list-item">
            <ListItemPrefix>
            <SquareLibrary className="w-5 text-yellowLight" />
            </ListItemPrefix>
            Residual Payments
          </ListItem>
        </Link>
        <Divider title='Products' />
        <Link to={'/ads'} onClick={close}>
          <ListItem className="list-item">
            <ListItemPrefix>
            <Table2 className="w-5 text-yellowLight" />
            </ListItemPrefix>
            Advertisements
          </ListItem>
        </Link>
        <Link to={'/bannerAds'} onClick={close}>
          <ListItem className="list-item">
            <ListItemPrefix>
            <Image className="w-5 text-yellowLight" />
            </ListItemPrefix>
            Banner Ads
          </ListItem>
        </Link>
        <Divider title='Account' />
        <Link to={'/referrals'} onClick={close}>
          <ListItem className="list-item">
            <ListItemPrefix>
            <UsersRound className="w-5 text-yellowLight" />
            </ListItemPrefix>
            My Referrals
          </ListItem>
        </Link>
        <Link to={'/activities'} onClick={close}>
          <ListItem className="list-item">
            <ListItemPrefix>
              <SquareGanttChart className="w-5 text-yellowLight" />
            </ListItemPrefix>
            Recent Activity
          </ListItem>
        </Link>
        <a href={appConfig.telgramBotLink} target="blank" onClick={close}>
          <ListItem className="list-item">
            <ListItemPrefix>
            <BotMessageSquare className="w-5 text-yellowLight" />
            </ListItemPrefix>
            Telegram Notifier
          </ListItem>
        </a>
        <ListItem onClick={async () => { device.deleteToken(); await auth.bootApp(); window.location.reload(); }} className="list-item">
          <ListItemPrefix>
          <LogOut className="w-5 text-yellowLight" />
          </ListItemPrefix>
          Logout
        </ListItem>
      </List>
    </Card>
  );
};
export default Sidebar;