import React, { useEffect } from "react";
import { useAppDialog } from '../../Hooks/appDialog';
import { useCallAPI } from "../../Hooks/callAPI";
import { AppDialog } from '../modals/AppDialog';
import { useUserAuth } from "../../Contexts/authContext";

const ShowAd = () => {

  const callAPI = useCallAPI();
  const appDialog = useAppDialog();
  const auth = useUserAuth();

  useEffect(() => {
    //Check if user has watched ad in last 24 hours
    const lastAdWatched = localStorage.getItem('lastAdWatched');
    const currentTime = Math.floor(Date.now() / 1000);
    if(lastAdWatched === null || lastAdWatched*1 + 3600 < currentTime*1) {
      getAd();
    }
  }, []);

  const getAd = async () => {
    try {
      
      if(!auth.loggedIn) return;

      const res = await callAPI("user/getAd");
      //open ad in new tab and show timer for 30 seconds
      if(res) {
        appDialog.open({
          header: 'Login Advertisement',
          description: 'Watch this sponsored ad to proceed to backoffice. Popup will hide once ad is watched for 10 seconds.',
          backdropDismiss: false,
          buttons: [
            {
              text: 'Open Ad',
              action: openAd
            }
          ]
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const openAd = async () => {
    try {
      const res = await callAPI("user/openAd");
      //Store in localstorage last watched ad time
      localStorage.setItem('lastAdWatched', Math.floor(Date.now() / 1000));
      //Open ad in new tab
      window.open(res.link, '_blank');
      setTimeout(() => {
        appDialog.close();
      }, 10000);
    } catch (error) {
      console.log(error);
    }
  }
  
  return (
    <AppDialog hook={appDialog} />
  );
};

export default ShowAd;