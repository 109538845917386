import React from "react";
import { CommonCard } from "../../../Utility/Utility";

const StatBox = ({ icon, title, total }) => {
  return (
    <CommonCard bg="#222222">
      {icon}
      <p className="text-grey text-base font-normal mb-1">{title}</p>
      <strong className="text-white text-[26px] font-bold block leading-tight">
        {total}
      </strong>
    </CommonCard>
  );
};

export default StatBox;
