import React from "react";
import logo2 from "../../Assets/logo-two.png";
import AppRouter from "../../Routers/AppRouter";
import appConfig from "../../Utility/AppConfig";

const AuthLayout = () => {

  return (
    <div className="bg-gradient-to-r from-black to-[#191919] min-h-screen flex flex-col items-center relative p-4 pt-6">
      <div className="register bg-gradient-to-r from-[#131313] rounded-xl to-[#282828] max-w-[480px] w-full mx-auto p-5 md:p-7 my-0 sm:my-10">
        <img className="mb-10 mt-2.5 w-full max-w-[350px] mx-auto" src={logo2} alt="logo" />
        <AppRouter />
      </div>
      <p className="text-center mt-6 mb-2 xl:mt-0 xl:absolute bottom-10 right-10 text-grey font-normal text-sm flex items-center gap-3">
        Copyright &copy; {appConfig.appName} | v{appConfig.version}
      </p>
    </div>
  );
};

export default AuthLayout;
