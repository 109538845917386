import {
  Bars3Icon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import {
  Drawer,
  IconButton
} from "@material-tailwind/react";
import React from "react";
import Sidebar from "./Sidebar";

export function SidebarWithBurgerMenu() {
  
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);

  return (
    <>
      <IconButton variant="text" size="lg" onClick={openDrawer}>
        {isDrawerOpen ? (
          <XMarkIcon className="h-8 w-8 stroke-2" />
        ) : (
          <Bars3Icon className="h-8 w-8 stroke-2 text-indigo-200" />
        )}
      </IconButton>
      <Drawer open={isDrawerOpen} onClose={closeDrawer}>
        <div className="drawer">
          <Sidebar close={closeDrawer} />
        </div>
      </Drawer>
    </>
  );
}
