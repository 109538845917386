import { Button, Dialog, DialogBody } from '@material-tailwind/react';

export const AppDialog = ({ hook }) => {

    return (
        <Dialog size='sm' open={hook.isOpen} dismiss={hook.close} handler={hook.data.backdropDismiss ? hook.close : null}>
            <DialogBody className='p-7 text-center'>
                {hook.data.icon && 
                    <div className='mb-4 flex justify-center'>
                        {hook.data.icon}
                    </div>
                }
                <div className="text-lg font-bold leading-6 text-gray-900">
                    {hook.data.header}
                </div>
                <div className="mt-2">
                    <p className="text-sm font-normal text-gray-600">{hook.data.description}</p>
                </div>
                <div className='mt-4 space-x-2'>
                    {hook.data.buttons && hook.data.buttons.length > 0 ?
                        <>
                            {hook.data.buttons.map((e, k) => {
                                return (
                                    <Button
                                        key={k}
                                        type="button"
                                        variant={e.variant ? e.variant : 'gradient'}
                                        color={e.color ? e.color : 'red'}
                                        onClick={e.action ? e.action : hook.close}
                                    >
                                        {e.text}
                                    </Button>
                                );
                            })}
                        </>
                    :
                        <Button
                            type="button"
                            color='red'  
                            variant='gradient'
                            onClick={hook.close}
                        >
                            Close
                        </Button>
                    }
                </div>
            </DialogBody>
        </Dialog>
    )
}