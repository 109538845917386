import { Typography } from "@material-tailwind/react";
import React from "react";
import appConfig from "../../Utility/AppConfig";

const DashFooter = () => {
  return (
    <div className="flex justify-end py-3">
        <Typography
        variant="small"
        className="text-sm font-bold text-grey"
        >
            &copy; {appConfig.appName} | v{appConfig.version}
        </Typography>
    </div>
  );
};

export default DashFooter;