import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LicenseDownlines from '../../Components/dash/license/downlines';
import LicensePayments from '../../Components/dash/license/payments';
import { useUserAuth } from '../../Contexts/authContext';
import { useCallAPI } from '../../Hooks/callAPI';
import { CommonCard, ContentLoading, TitlePrimary } from '../../Utility/Utility';
import LicenseData from '../../Components/dash/license/licenseData';
import { CircleArrowLeft } from 'lucide-react';

const License = () => {

  const params = useParams();
  const callAPI = useCallAPI();
  const navigate = useNavigate();
  const auth = useUserAuth();

  const [license, setLicense] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(auth.loggedIn) {
      getLicenseData();
    }
  }, [auth.loggedIn]);

  const getLicenseData = async () => {
    try {
      setLoading(true);
      const res = await callAPI('user/getLicenseData/' + params.id);

      setLicense(res);
      setLoading(false);

    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <div className='mt-6'>
        <CommonCard>
            <TitlePrimary><CircleArrowLeft onClick={() => navigate('/licenses')} className='mr-2 text-purpleLight' />Viewing License #{params.id}</TitlePrimary>
            {loading || !license.licenseData ?
                <div className='flex justify-center pt-3'><ContentLoading /></div>
            :
                <div className="grid grid-cols-1 lg:grid-cols-[67%_30%] mt-5 gap-[3%]">
                    <div>
                        <div><LicenseData licenseData={license.licenseData} /></div>
                        <div className='mt-4'><LicensePayments id={params.id} /></div>
                    </div>
                    <div><LicenseDownlines downlines={license.downlines} /></div>
                </div>
            }
        </CommonCard>
    </div>
  );
};
export default License;