import React from "react";
import {
  Navigate,
  Route,
  Routes
} from "react-router-dom";

//Import Auth pages
import Login from "../Pages/auth/Login";

//Import dashboard pages
import Register from "../Pages/auth/Register";
import Splash from "../Pages/auth/Splash";
import Activities from "../Pages/dash/Activities";
import AllLicensePayments from "../Pages/dash/AllLicensePayments";
import Dashboard from "../Pages/dash/Dashboard";
import License from "../Pages/dash/License";
import Licenses from "../Pages/dash/Licenses";
import Purchase from "../Pages/dash/Purchase";
import Referrals from "../Pages/dash/Referrals";
import Withdraw from "../Pages/dash/Withdraw";
import AllResidualPayments from "../Pages/dash/AllResidualPayments";
import Ads from "../Pages/dash/Ads";
import BannerAds from "../Pages/dash/BannerAds";
import Transfer from "../Pages/dash/Transfer";

export default function AppRouter() {
  return (
    <Routes>
        {/* Dashboard Routes */}
        <Route exact path="/" element={<Navigate to="/dashboard" />}></Route>

        <Route path="/dashboard" element={<Dashboard />}></Route>

        {/* Licenses */}
        <Route path="/licenses" element={<Licenses />}></Route>

        <Route path="/license/:id" element={<License />}></Route>

        <Route path="/purchase" element={<Purchase />}></Route>
        <Route path="/purchase/:status/:orderid" element={<Purchase />}></Route>
        
        <Route path="/withdraw" element={<Withdraw />}></Route>
        <Route path="/withdraw/:status/:orderid" element={<Withdraw />}></Route>

        <Route path="/allLicensePayments" element={<AllLicensePayments />}></Route>
        <Route path="/allResidualPayments" element={<AllResidualPayments />}></Route>

        <Route path="/referrals" element={<Referrals />}></Route>
        <Route path="/activities" element={<Activities />}></Route>

        <Route path="/ads" element={<Ads />}></Route>
        <Route path="/bannerAds" element={<BannerAds />}></Route>

        <Route path="/transfer" element={<Transfer />}></Route>

        {/* Authentication Routes */}
        <Route path="/splash" element={<Splash />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/login/:login" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>

        {/* Referral Link */}
        <Route path="/ref/:ref" element={<Splash />}></Route>

    </Routes>
  );
}