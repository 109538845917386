import { Card } from "@material-tailwind/react";
import React from "react";
import { useHelpers } from "../../../Hooks/helpers";

const StatsGeneral = ({ data }) => {

  const helper = useHelpers();
  return (
    <Card className="h-full w-full overflow-scroll bg-transparent shadow-none -mt-3">
      <table className="cmn-table w-full min-w-max table-auto text-left bg-transparent">
        <tbody>
          {data.map(({ statKey, statValue }, index) => {
            const classes = "cmn-table-td px-2 py-3";
            return (
              <tr key={Math.random() * 2}>
                <td className={classes}>
                  <p>{statKey}</p>
                </td>
                <td className={classes}>
                  <p className="flex justify-end">{statValue}</p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
};

export default StatsGeneral;
