import { Anvil, AreaChart, Blocks, HandCoins, User2 } from "lucide-react";
import React, { useEffect, useState } from "react";
import ActivateResidual from "../../Components/dash/dashboard/ActivateResidual";
import ClaimPowerline from "../../Components/dash/dashboard/ClaimPowerline";
import ClaimRewards from "../../Components/dash/dashboard/ClaimRewards";
import PurchaseLicense from "../../Components/dash/dashboard/PurchaseLicense";
import StatBox from "../../Components/dash/dashboard/StatBox";
import StatsGeneral from "../../Components/dash/dashboard/StatsGeneral";
import UpgradeStar from "../../Components/dash/dashboard/UpgradeStar";
import { useUserAuth } from "../../Contexts/authContext";
import { useCallAPI } from "../../Hooks/callAPI";
import { useHelpers } from "../../Hooks/helpers";
import { CommonCard, LoadingData, TitlePrimary } from "../../Utility/Utility";

const Dashboard = () => {

  const callAPI = useCallAPI();
  const auth = useUserAuth();
  const helper = useHelpers();

  const [dashData, setDashData] = useState(false);

  useEffect(() => {
    if(auth.loggedIn) {
      refreshData();
    }
  }, [auth.loggedIn]);

  const refreshData = async (forceRefresh = 'no') => {
    try {
      const res = await callAPI('user/dash/' + forceRefresh);
      setDashData(res);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {dashData ?
        <>
          <div className="grid grid-cols-1 lg:grid-cols-4 mt-6 gap-5">
            <StatBox icon={<User2 size={30} className="text-amber-500 mb-4" />} title="Total Users" total={dashData.siteStats.totalusers} />
            <StatBox icon={<Anvil size={30} className="text-amber-500 mb-4" />} title="Licenses Purchased" total={dashData.siteStats.totallicenses} />
            <StatBox icon={<Blocks size={30} className="text-amber-500 mb-4" />} title="Residuals Activated" total={dashData.siteStats.totalresiduals} />
            <StatBox icon={<HandCoins size={30} className="text-amber-500 mb-4" />} title="Users Earned" total={<helper.AmountToCurrency amount={dashData.siteStats.totalResidualEarnings*1 + dashData.siteStats.totalLicenseEarnings*1} />} />
          </div>
          <div className="">
            <div className="grid lg:grid-cols-2 gap-4 lg:gap-6 mt-6">
              <div className="rounded-lg overflow-hidden">
                <PurchaseLicense refreshData={refreshData} />
              </div>
              <div className="bg-[#333333] flex flex-col h-full rounded-lg overflow-hidden">
                <ActivateResidual data={dashData} refreshData={refreshData} />
              </div>
            </div>
            <div className="grid lg:grid-cols-3 gap-4 lg:gap-6 mt-6">
              <div className="rounded-lg overflow-hidden">
                <ClaimRewards data={dashData} refreshData={refreshData} />
              </div>
              <div className="rounded-lg overflow-hidden">
                <ClaimPowerline data={dashData} refreshData={refreshData} />
              </div>
              <div className="rounded-lg overflow-hidden">
                <UpgradeStar data={dashData} refreshData={refreshData} />
              </div>
            </div>

            <div className="grid lg:grid-cols-3 gap-4 lg:gap-6 mt-6">
              
              <CommonCard bg="#222222">
                <TitlePrimary><AreaChart className="text-amber-500 mr-2" /> My Statistics</TitlePrimary>
                <StatsGeneral data={[
                  {
                    statKey: "Username",
                    statValue: <helper.CopyText value={dashData.userStats.username} />,
                  },
                  {
                    statKey: "Registered Address",
                    statValue: <helper.trimAddress address={dashData.userStats.registered_address} copy />,
                  },
                  {
                    statKey: "Referrer ID and Username",
                    statValue: dashData.userStats.referrer_id + ' (' + dashData.userStats.referrer_username + ')',
                  },
                  {
                    statKey: "Total Referrals (Today)",
                    statValue: dashData.userStats.total_referrals + ' (' + dashData.userStats.referred_today + ')',
                  },
                  {
                    statKey: "Active Referrals",
                    statValue: dashData.userStats.refs_with_one_position,
                  },
                  {
                    statKey: "Total Earnings",
                    statValue: <helper.AmountToCurrency amount={dashData.userStats.total_license_earnings*1 + dashData.userStats.total_residual_earnings*1} />,
                  },
                  {
                    statKey: "Total Withdrawals",
                    statValue: <helper.AmountToCurrency amount={dashData.userStats.total_claimed} />,
                  }
                ]} />
              </CommonCard>

              <CommonCard bg="#333333">
                <TitlePrimary><AreaChart className="text-amber-500 mr-2" /> My License Stats</TitlePrimary>
                <StatsGeneral data={[
                  {
                    statKey: "Total Licenses",
                    statValue: dashData.userLicenseStats.licenses,
                  },
                  {
                    statKey: "Active Licenses",
                    statValue: dashData.userLicenseStats.active_licenses,
                  },
                  {
                    statKey: "Expired Licenses",
                    statValue: dashData.userLicenseStats.expired_licenses,
                  },
                  {
                    statKey: "License Earnings",
                    statValue: <helper.AmountToCurrency amount={dashData.userLicenseStats.total_earned} />,
                  },
                  {
                    statKey: "Total 2x11 Payouts",
                    statValue: <helper.AmountToCurrency amount={dashData.userLicenseStats.licensePayoutSum} />,
                  },
                  {
                    statKey: "Total FastStart",
                    statValue: <helper.AmountToCurrency amount={dashData.userLicenseStats.fastStartLicenseSum} />,
                  },
                  {
                    statKey: "Total FastStart Missed",
                    statValue: <helper.AmountToCurrency amount={dashData.userLicenseStats.missedFastStartSum} />,
                  },
                  {
                    statKey: "Total line Reward",
                    statValue: <helper.AmountToCurrency amount={dashData.userLicenseStats.lineRewardsSum} />,
                  },
                  {
                    statKey: "Total Daily Win",
                    statValue: <helper.AmountToCurrency amount={dashData.userLicenseStats.rewardsClaimedSum} />,
                  }
                ]} />
              </CommonCard>

              <CommonCard bg="#333333">
                <TitlePrimary><AreaChart className="text-amber-500 mr-2" /> My Residual Stats</TitlePrimary>
                <StatsGeneral data={[
                  {
                    statKey: "Residual Active?",
                    statValue: dashData.userResidualStats.residuals*1 > 0 ? 'Yes' : 'No',
                  },
                  {
                    statKey: "Total Earnings",
                    statValue: <helper.AmountToCurrency amount={dashData.userResidualStats.total_earned} />,
                  },
                  {
                    statKey: "Total 2x7 Payouts",
                    statValue: <helper.AmountToCurrency amount={dashData.userResidualStats.payoutSum} />,
                  },
                  {
                    statKey: "Total FastStart",
                    statValue: <helper.AmountToCurrency amount={dashData.userResidualStats.fastStartSum} />,
                  },
                  {
                    statKey: "Total FastStart Missed",
                    statValue: <helper.AmountToCurrency amount={dashData.userResidualStats.missedFastStartSum} />,
                  },
                  {
                    statKey: "Checkmatch Level 1 Earned",
                    statValue: <helper.AmountToCurrency amount={dashData.userResidualStats.cmlvl1Sum} />,
                  },
                  {
                    statKey: "Missed Checkmatch Level 1",
                    statValue: <helper.AmountToCurrency amount={dashData.userResidualStats.missedCMlvl1Sum} />,
                  },
                  {
                    statKey: "Checkmatch Level 2 Earned",
                    statValue: <helper.AmountToCurrency amount={dashData.userResidualStats.cmlvl2Sum} />,
                  },
                  {
                    statKey: "Missed Checkmatch Level 2",
                    statValue: <helper.AmountToCurrency amount={dashData.userResidualStats.missedCMlvl2Sum} />,
                  }
                ]} />
              </CommonCard>
            </div>
          </div>
        </>
      :
        <LoadingData />
      }
    </>
  );
};
export default Dashboard;
