import { ArrowsRightLeftIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from "@material-tailwind/react";
import { useState } from "react";
import { useForm } from 'react-hook-form';
import toast from "react-hot-toast";
import * as yup from 'yup';
import { useUserAuth } from "../../../Contexts/authContext";
import { useCallAPI } from "../../../Hooks/callAPI";
import { useHelpers } from "../../../Hooks/helpers";
import { CommonCard, Title } from "../../../Utility/Utility";

const TransferBlock = () => {

  const callAPI = useCallAPI();
  const auth = useUserAuth();
  const helper = useHelpers();
  
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState({});
 
  const transfer = async () => {
    try {
      setLoading(true);
      const res = await callAPI('user/transfer', getValues());
      toast.success('Transfer successful. Funds will reflect in receipient account shortly.');
      await auth.bootApp();
      setLoading(false);
    } catch (error) {
      if(error.code && error.code === 403) { //Handle form error
        setFormError(error.errData);
      }
      setLoading(false);
      console.log(error);
    }
  }

  const FormSchema = yup.object().shape({
    amount: yup.number().label('Amount must be a number')
      .required('Amount is required')
      .min(0.01, 'Amount must be at least 0.01')
      .test('is-decimal', 'Amount must be a decimal up to 2 places', (value) => {
        if (value) {
          const decimalPlaces = (value.toString().split('.')[1] || []).length;
          return decimalPlaces <= 2;
        }
        return true;
      }),
    username: yup.string().required('Please enter receiver username')
  });

  const {
      getValues,
      register,
      setValue,
      formState: { errors, isValid }
  } = useForm({ mode: 'onChange', resolver: yupResolver(FormSchema) })

  return (
    <>
      <div className='mb-5 text-center'>
        <CommonCard>
          <h4 className='font-bold text-3xl text-amber-400'><helper.AmountToCurrency amount={auth?.appData.user.balance} /></h4>
          <p className='text-gray-400'>My USD Balance</p>
        </CommonCard>
      </div>
      <CommonCard>
        <div className="mb-2">
          <Title
            title={{ icon: <ArrowsRightLeftIcon className='w-7 text-amber-600' />, label: "Transfer Balance" }}
          />
        </div>
        <div className="text-left">
          <div className="text-sm mt-4 leading-5 text-gray-400">
            Enter amount to transfer in USD and username to send to
          </div>
          <div className="my-4">
            <input
                type="text"
                placeholder="0"
                {...register('amount')}
                className="border-2 rounded-lg border-[#888] text-white w-full h-[45px] bg-transparent px-4 py-5 focus:outline-none placeholder:text-lightblue"
            />
            {formError?.address && <p className="text-red-400 text-xs my-2">{formError?.address.msg}</p>}
            {errors.amount?.message && <p className="text-red-400 text-xs my-2">{errors.amount?.message}</p>}
            {formError?.amount && <p className="text-red-400 text-xs my-2">{formError?.amount.msg}</p>}
          </div>
          <div className="my-4">
            <input
                type="text"
                placeholder="username"
                {...register('username')}
                className="border-2 rounded-lg border-[#888] text-white w-full h-[45px] bg-transparent px-4 py-5 focus:outline-none placeholder:text-lightblue"
            />
            {formError?.address && <p className="text-red-400 text-xs my-2">{formError?.address.msg}</p>}
            {errors.username?.message && <p className="text-red-400 text-xs my-2">{errors.username?.message}</p>}
            {formError?.username && <p className="text-red-400 text-xs my-2">{formError?.username.msg}</p>}
          </div>
          <div className="flex justify-left mt-5">
            <Button
              loading={loading}
              disabled={loading || !isValid}
              onClick={transfer}
              variant="gradient"
              className="bg-gradient-to-r from-purpleDark to-purpleLight font-bold text-lg rounded-lg capitalize"
            >
              Transfer Now
            </Button>
          </div>
        </div>
      </CommonCard>
    </>
  );
};
export default TransferBlock;