import { Button, Collapse, IconButton, Tooltip } from '@material-tailwind/react';
import { Pause } from 'lucide-react';
import React, { useState } from 'react';
import Timestamp from 'react-timestamp';
import CreateNewBannerAd from '../../Components/dash/ads/newBannerAd';
import { useUserAuth } from '../../Contexts/authContext';
import { useCallAPI } from '../../Hooks/callAPI';
import useDynamicTable, { DynamicPagination } from '../../Hooks/dynamicTable';
import { useHelpers } from '../../Hooks/helpers';
import { CommonCard, ContentLoading, TitlePrimary } from '../../Utility/Utility';

const BannerAds = () => {

  const helper = useHelpers();
  const callAPI = useCallAPI();
  const auth = useUserAuth();

  const [showNewAd, setShowNewAd] = useState(false);
  const [stopping, setStopping] = useState(false);

  const { data, loading, currentPage, totalPages, handlePageChange, fetchData } = useDynamicTable('user/getBannerAds');

  let tableHead = [
    <>Link</>, 
    <>Banner</>, 
    <>Available Credits</>, 
    <>Impressions</>,
    <>Clicks</>,
    <>Status</>,
    <>Created</>,
    <></>
  ];

  const stopAd = async (id) => {
    try {
      setStopping(true);
      await callAPI('user/deleteBannerAd/' + id);
      await fetchData();
      await auth.bootApp();
      setStopping(false);
    } catch (error) {
      setStopping(false);
      console.log(error);
    }
  }

  return (
    <div className='mt-6'>
      <CommonCard>
        <TitlePrimary>
          <div className='w-full flex justify-between items-center'>
            <div>My Banner Ads</div>
            <div className='flex justify-end items-center'>
              <Button size='sm' color='amber' className='ml-4' variant='gradient' onClick={() => setShowNewAd(!showNewAd)}>{!showNewAd ? 'Create New' : 'Close'}</Button>
              <div className='ml-5 text-amber-400 font-bold text-right'>
                {auth?.appData?.user?.banner_credits} Credits
                <p className='text-sm text-gray-400'>Available</p>
              </div>
            </div>
          </div>
          
        </TitlePrimary>
        <Collapse open={showNewAd}>
          <div className='mb-5 border-4 border-[#7b7b7b]/30 bg-gray-900 px-2 py-5 rounded-10'>
            <CreateNewBannerAd auth={auth} refresh={fetchData} />
          </div>
        </Collapse>
        <div className="w-full overflow-scroll px-0 table-custom">
          <table className="w-full">
            <thead>
              <tr>
                {tableHead.map((head, key) => (
                  <th key={key} className="">{head}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {(data.length > 0 && !loading) && data.map(
                (d, index) => {
                  const classes =  "p-4";
                  return (
                    <tr key={index}>
                      <td className={classes}>
                        <helper.CopyText value={d.link} />
                      </td>
                      <td className={classes}>
                        <img src={d.banner} className='max-w-[200px]' />
                      </td>
                      <td className={classes}>
                        {d.available_credits}
                      </td>
                      <td className={classes}>
                        {d.impressions}
                      </td>
                      <td className={classes}>
                        {d.clicks}
                      </td>
                      <td className={classes}>
                        <helper.renderStatusChip status={d.status} color='black' />
                      </td>
                      <td className={classes}>
                        <Timestamp date={d.created} /><br/>
                        <Timestamp date={d.created} relative className='opacity-60' />
                      </td>
                      <td className='flex gap-1 justify-end'>
                        {d.status === 'active' && <Tooltip content='Stop Ad'><IconButton color="red" disabled={stopping} onClick={() => stopAd(d.id)} size="sm"><Pause size={15} /></IconButton></Tooltip>}
                      </td>
                    </tr>
                  );
                },
              )}
              {loading && 
                <tr>
                  <td colSpan={tableHead.length}><div className='flex justify-center pt-3'><ContentLoading /></div></td>
                </tr>
              }
              {((!data || data.length === 0) && !loading) && 
                <tr>
                  <td colSpan={tableHead.length}><div className='flex justify-center pt-3 opacity-50'>No rows found</div></td>
                </tr>
              }
            </tbody>
          </table>
        </div>
        <DynamicPagination totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
      </CommonCard>
    </div>
  );
};
export default BannerAds;