import { BeatLoader } from 'react-spinners';

export default function LayoutLoading() {  
    return (
        <div className="grid h-screen place-items-center bg-gradient-to-r from-black to-[#191919]">
            <div className="text-center">
                <BeatLoader
                    color='gold'
                    speedMultiplier={0.8}
                    loading={true}
                    size='15px'
                    aria-label="Loading"
                    data-testid="loader"
                />
            </div>
        </div>
    );
}